import { Client } from 'minio'
import { ClientBase } from '@/common/oss/ClientBase'
import * as stream from 'stream'

export default class MinioClient implements ClientBase {
  minioClient: Client
  credential: OssCredentialVo

  constructor (result: OssCredentialVo) {
    const split1 = result.endpoint.split('//')
    const split2 = split1[1].split(':')
    const endPoint = split2[0]
    const port = parseInt(split2[1])

    this.credential = result

    this.minioClient = new Client({
      useSSL: result.ssl,
      port,
      endPoint,
      accessKey: result.credentials.access_key_id,
      secretKey: result.credentials.access_key_secret,
      sessionToken: result.credentials.security_token
    })
  }

  async putObject (key: string, file: File, options?: { onprogress?(e: Event): void; }) {
    const _this = this
    return new Promise((resolve, reject) => {
      try {
        // 准备上传
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function (e) { // 读取完成触发，无论成功或失败
          const dataurl = e.target?.result as string
          // base64转blob
          const blob = _this.toBlob(dataurl)
          // blob转arrayBuffer
          const reader2 = new FileReader()
          reader2.readAsArrayBuffer(blob)
          reader2.onload = function (ex) {
            // 定义流
            const bufferStream = new stream.PassThrough()
            // 将buffer写入
            bufferStream.end(Buffer.from(ex.target!.result as ArrayBuffer))
            // 上传
            // @ts-ignore
            _this.minioClient.putObject(_this.credential.bucket, key, bufferStream, file.size, options || {}, (error: Error, result) => {
              if (error) {
                reject(error)
              } else {
                resolve(result)
              }
            }
            )
          }
        }
      } catch (e:any) {
        reject(e)
      }
    })
  }

  private toBlob (base64Data:string) {
    let byteString = base64Data
    if (base64Data.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(base64Data.split(',')[1]) // base64 解码
    } else {
      byteString = unescape(base64Data.split(',')[1])
    }
    // 获取文件类型
    const mimeString = base64Data.split(';')[0].split(':')[1] // mime类型

    // ArrayBuffer 对象用来表示通用的、固定长度的原始二进制数据缓冲区
    // let arrayBuffer = new ArrayBuffer(byteString.length) // 创建缓冲数组
    // let uintArr = new Uint8Array(arrayBuffer) // 创建视图

    const uintArr = new Uint8Array(byteString.length) // 创建视图

    for (let i = 0; i < byteString.length; i++) {
      uintArr[i] = byteString.charCodeAt(i)
    }
    // 生成blob
    // 使用 Blob 创建一个指向类型化数组的URL, URL.createObjectURL是new Blob文件的方法,可以生成一个普通的url,可以直接使用,比如用在img.src上
    return new Blob([uintArr], {
      type: mimeString
    })
  }

  async getSignUrl (key: string, options: StringKeyRecord<any>): Promise<string> {
    const _this = this
    return new Promise<string>((resolve, reject) => {
      _this.minioClient.presignedUrl('GET', _this.credential.bucket, key, _this.credential.credentials.expire,
        function (error: Error, result: any) {
          if (error) {
            reject(error)
          } else {
            resolve(result)
          }
        }
      )
    }
    )
  }
}
