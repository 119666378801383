import JoyStickServiceBase from '@/common/mqtt/JoyStickServiceBase'
import JlinkTask from '@/common/global/JlinkTask'
import { mittError } from '@/common/mitt/mitt'
import NotifyException from '@/common/errors/NotifyException'
import JlinkBindThis from '@/common/annotate/JlinkBindThis'
export default class JoyStickService extends JoyStickServiceBase {
  private button2TimeCounter = 0
  private button1012Delay = JlinkTask.loopDelay(function (value: 10 | 11 | 20 | 21) {
    // @ts-ignore
    const _this = this as JoyStickService
    _this.zoomParams = undefined
    if (value === 10) {
      _this.zoomIncrease(5)
    }
    if (value === 20) {
      _this.zoomDecrease(5)
    }
    if (value === 11) {
      _this.zoomParams = 'increase'
    }
    if (value === 21) {
      _this.zoomParams = 'decrease'
    }
  }.bind(this), 800)

  private button2Delay = JlinkTask.loopDelay(function (value: 0 | 1) {
    // @ts-ignore
    const _this = this as JoyStickService
    if (value === 0) {
      _this.returnBack()
    } else {
      _this.toggleWaylineTaskPauseOrResume()
    }
  }.bind(this), 3000)

  //
  private button1012TimeCounter = 0
  private gimbalResetMode: 0 | 1 | 2 | 3 = 1

  private heightThrottleCurve = 0

  async create (activeCallback: (a: boolean) => void): Promise<void> {
    await super.create(activeCallback)
    this.activeCallback && this.activeCallback(false)
    // @ts-ignore
    window.addEventListener('joyStickError', this.catchError)
    // @ts-ignore
    window.addEventListener('joyStickOpen', this.handleJoyStickOpen)
    // @ts-ignore
    window.addEventListener('joyStickTrigger', this.handleJoyStickTrigger)
    this.enter(true)
    const _this = this
    this.makeButtonWatch(0, async function (v) {
      if (v === 'down') {
        _this.toggleRecord()
      }
    })
    this.makeButtonWatch(2, async function (v) {
      if (v === 'down') {
        _this.button2TimeCounter = Date.now()
        _this.button2Delay.triggerDelay(0)
      }
      if (v === 'up') {
        if (Date.now() - _this.button2TimeCounter < 500) {
          _this.button2Delay.triggerNow(1)
        }
      }
    })
    this.makeButtonWatch(3, function (v) {
      _this.controlKeyPress = v === 'down'
    })
    this.makeButtonWatch(4, async function (v) {
      if (v === 'down') {
        _this.flyParams.seq = undefined
        _this.handleEmergencyStop()
        console.log('暂时定义急停')
      }
    })
    this.makeButtonWatch(5, function (v) {
      if (v === 'down') {
        _this.gimbalParamsChange('top', 5)
      }
    })
    this.makeButtonWatch(6, function (v) {
      if (v === 'down') {
        _this.gimbalParamsChange('right', 5)
      }
    })
    this.makeButtonWatch(7, function (v) {
      if (v === 'down') {
        _this.gimbalParamsChange('down', 5)
      }
    })
    this.makeButtonWatch(8, function (v) {
      if (v === 'down') {
        _this.gimbalParamsChange('left', 5)
      }
    })
    this.makeButtonWatch(9, function (v) {
      if (v === 'down') {
        if (_this.gimbalResetMode === 0) {
          _this.gimbalResetMode = 1
        } else {
          _this.gimbalResetMode = 0
        }
        _this.gimbalReset(_this.gimbalResetMode)
      }
    })
    this.makeButtonWatch(10, function (v) {
      if (v === 'down') {
        _this.button1012TimeCounter = Date.now()
        _this.button1012Delay.triggerDelay(11)
      }
      if (v === 'up') {
        if (Date.now() - _this.button1012TimeCounter < 500) {
          _this.button1012Delay.triggerNow(10)
        }
      }
    })
    this.makeButtonWatch(12, function (v) {
      if (v === 'down') {
        _this.button1012TimeCounter = Date.now()
        _this.button1012Delay.triggerDelay(21)
      }
      if (v === 'up') {
        if (Date.now() - _this.button1012TimeCounter < 500) {
          _this.button1012Delay.triggerNow(20)
        }
      }
    })
    this.makeButtonWatch(14, function (v) {
      if (v === 'down') {
        _this.cameraFocalLengthReset()
      }
    })

    this.makeButtonWatch(19, function (v) {
      if (v === 'down') {
        _this.switchLiverLen()
      }
    })
    this.makeButtonWatch(21, function (v) {
      if (v === 'down') {
        // 拍照和录像最好换一下
        _this.takePhoto()
      }
    })
    this.makeButtonWatch(22, function (v) {
      if (v === 'down') {
        _this.zoomIncrease(1)
      }
    })
    this.makeButtonWatch(23, function (v) {
      if (v === 'down') {
        _this.zoomDecrease(1)
      }
    })

    this.makeButtonWatch(24, function (v) {
      if (v === 'down') {
        // 每秒1米上升
        _this.flyAction(undefined, undefined, undefined, -10000)
      } else {
        _this.flyAction(undefined, undefined, undefined, 0)
      }
    })
    this.makeButtonWatch(25, function (v) {
      if (v === 'down') {
        // 每秒1米下将
        _this.flyAction(undefined, undefined, undefined, 10000)
      } else {
        _this.flyAction(undefined, undefined, undefined, 0)
      }
    })

    this.makeButtonWatch(26, function (v) {
      if (v === 'down') {
        mittError(new NotifyException('26键暂无实现', 'warning'))
      }
    })
    // this.makeButtonWatch(27, function (v) {
    //   if (v === 'down') {
    //     _this.toggleMapSwitch()
    //   }
    // })

    this.makeButtonWatch(28, function (v) {
      if (v === 'down') {
        mittError(new NotifyException('28键暂无实现', 'warning'))
      }
    })

    this.makeHatWatch(0, function (v) {
      if (v === 'up') {
        _this.flyAction(undefined, undefined, undefined, -_this.heightThrottleCurve)
      } else if (v === 'down') {
        _this.flyAction(undefined, undefined, undefined, _this.heightThrottleCurve)
      } else {
        _this.flyAction(undefined, undefined, undefined, 0)
      }
    })
    /* -----飞行控制------- */
    this.makeAxisWatch(0, function (v) {
      // 飞机左右移动
      _this.flyAction(v, undefined, undefined, undefined)
    })
    this.makeAxisWatch(1, function (v) {
      // 飞机前后移动
      _this.flyAction(undefined, v, undefined, undefined)
    })

    // /* -----飞行控制------- */
    // this.makeAxisWatch(2, function (v) {
    //   // 飞机左右移动
    //   _this.flyAction(undefined, undefined, v, undefined)
    // })
    // this.makeAxisWatch(3, function (v) {
    //   // 飞机前后移动
    //   _this.flyAction(undefined, undefined, undefined, v)
    // })

    this.makeAxisWatch(2, function (v) {
      _this.heightThrottleCurve = ((65535 / 2) - v) / 2
    })

    this.makeAxisWatch(5, function (v) {
      // 飞机角度转动
      _this.flyAction(undefined, undefined, -v, undefined)
    })

    // 自定义键控制飞机上升下降落
    // this.makeAxisWatch(99999, function (v) {
    //   _this.flyAction(undefined, undefined, undefined, v)
    // })
  }

  async destroy (): Promise<void> {
    this.enter(false)
    // @ts-ignore
    window.removeEventListener('joyStickError', this.catchError)
    // @ts-ignore
    window.removeEventListener('joyStickOpen', this.handleJoyStickOpen)
    // @ts-ignore
    window.removeEventListener('joyStickTrigger', this.handleJoyStickTrigger)
    super.destroy()
  }

  private enter (enter: Boolean) {
    const event = document.createEvent('CustomEvent')
    event.initCustomEvent('openJoystick', true, true, enter)
    document.dispatchEvent(event)
  }

  //
  private catchError = function (e: CustomEvent<string>) {
    console.log('JoyStickDrcService', 'catchError:' + e.detail)
  }

  @JlinkBindThis
  private handleJoyStickOpen (e: CustomEvent<boolean>) {
    this.joystickButtonSave.value = []
    this.joystickWhichSave.clear()
    this.activeCallback && this.activeCallback(e.detail)
  }

  private async gimbalParamsChange (direction: 'left' | 'right' | 'top' | 'down', index: number) {
    const gp = [0.5, 0.5]
    switch (direction) {
      case 'down':
        gp[1] = gp[1] + 0.03 * index
        break
      case 'left':
        gp[0] = gp[0] - 0.03 * index
        break
      case 'right':
        gp[0] = gp[0] + 0.03 * index
        break
      case 'top':
        gp[1] = gp[1] - 0.03 * index
        break
    }
    if (gp[0] === 0.5 && gp[1] === 0.5) {
      return
    }
    this.cameraAim(gp)
  }
}
