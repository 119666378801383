import JoyStickServiceBase from '@/common/mqtt/JoyStickServiceBase'

export default class JoyStickService2 extends JoyStickServiceBase {
  async create (activeCallback: (a: boolean) => void): Promise<void> {
    await super.create(activeCallback)
    this.activeCallback && this.activeCallback(true)
    const _this = this
    /* -----飞行控制------- */
    this.makeAxisWatch(0, function (v) {
      // 飞机左右移动
      _this.flyAction(v, undefined, undefined, undefined)
    })
    this.makeAxisWatch(1, function (v) {
      // 飞机前后移动
      _this.flyAction(undefined, v, undefined, undefined)
    })

    this.makeAxisWatch(5, function (v) {
      // 飞机角度转动
      _this.flyAction(undefined, undefined, v, undefined)
    })
    // 自定义键控制飞机上升下降落
    this.makeAxisWatch(6, function (v) {
      _this.flyAction(undefined, undefined, undefined, v)
    })
  }
}
