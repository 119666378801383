import OSS from 'tiny-oss'
import { ossGet } from '@/api/api.system'
import axios from 'axios'
import { ClientBase } from '@/common/oss/ClientBase'
import AliClient from '@/common/oss/AliClient'
import MinioClient from '@/common/oss/MinioClient'
import { AppConfigModule } from '@/store/AppConfig'
import { reject } from 'lodash'
import JlinkUtils from '@/common/global/JlinkUtils'

export default class OssUtil implements OssUtilInterface {
  oss?: ClientBase
  stsTokenFreshTime = 0
  isLoading = false
  doAnySave: Function[] = []

  private async reload<T> (fun: (type: 'cache' | 'trigger') => T): Promise<void> {
    const _this = this
    if (this.isLoading) {
      this.doAnySave.push(fun)
    } else {
      const now = Date.now()
      if (!this.oss || now - this.stsTokenFreshTime >= 3000000) {
        try {
          this.isLoading = true
          const result = await ossGet()
          if (result.provider === 'ali') {
            _this.oss = new AliClient(result)
          } else if (result.provider === 'minio') {
            _this.oss = new MinioClient(result)
          } else {
            reject(new Error(`provider ${result.provider} is not support`))
            return
          }
          this.stsTokenFreshTime = now
        } catch (e: any) {
          console.log(e.message)
          throw e
        } finally {
          this.isLoading = false
        }
      }

      fun('trigger')
      for (const i of this.doAnySave) {
        i('cache')
      }
      this.doAnySave = []
    }
  }

  async getSignUrl (key: string, options ?: OSS.SignatureUrlOptions):
        Promise<string> {
    const _this = this
    return new Promise<string>((resolve, reject) => {
      _this.reload(() => {
        resolve(_this.oss!!.getSignUrl(key, options || {}))
      }).catch((e) => reject(e))
    })
  }

  putObject (key: string, file: File, options ?: OSS.PutOptions) {
    const _this = this
    return new Promise((resolve, reject) => {
      _this.reload(async () => {
        try {
          const result = await _this.oss!!.putObject(key, file, options)
          resolve(result)
        } catch (e) {
          reject(e)
        }
      }).catch((e) => reject(e))
    })
  }

  async upload (file
               :
               File, basePath
               :
               string, dir
               :
               string, options ?: OSS.PutOptions
  ):
        Promise<{
            key: string;
            type: string;
            size: number;
            name: string
        }> {
    console.log(AppConfigModule.commonPath)

    if (!basePath
    ) {
      throw new Error('目录basePath不存在')
    }
    if (!basePath) {
      throw new Error('dir不存在')
    }
    const { name } = file
    if (!name) {
      throw new Error('fileName 不存在 , 该文件格式错误')
    }
    const index = name.lastIndexOf('.')
    const type = name.slice(index + 1, name.length)
    const { size } = file
    const key = `${basePath}/${dir}/${JlinkUtils.date.parseTime(new Date())}/${JlinkUtils.random.uuid(32)}`

    await this.putObject(key, file, options)
    return {
      key, type, size, name
    }
  }

  // resolvingPowerSave = new Map<string, string>()
  //
  // async getFileResolutionRatio (fileObjectKey
  //                            :
  //                            string
  // ) {
  //   if (!this.resolvingPowerSave.get(fileObjectKey)) {
  //     try {
  //       const urls = await window.$oss.getSignUrl(fileObjectKey, { process: 'device/info' })
  //       const res = await axios.get(urls)
  //       console.error(res)
  //
  //       this.resolvingPowerSave.set(fileObjectKey, res.data.ImageWidth.value + 'x' + res.data.ImageHeight.value)
  //     } catch (e:any) {
  //       console.error(e.message)
  //       // 后期完善
  //       this.resolvingPowerSave.set(fileObjectKey, 'unknown')
  //     }
  //   }
  //   return this.resolvingPowerSave.get(fileObjectKey)!
  // }
  //
  async getPoster (fileObjectKey
                :
                string
  ) {
    return await this.getSignUrl(fileObjectKey, { process: 'video/snapshot,t_0,f_jpg' })
  }
}
