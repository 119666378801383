import axios, { AxiosInstance, AxiosProgressEvent, AxiosResponse, CancelTokenSource, CreateAxiosDefaults } from 'axios'
import ResponseException from '@/common/errors/ResponseException'
import RequestException from '@/common/errors/RequestException'

export default class JlinkAxiosRequest {
  private readonly service: AxiosInstance
  private cancelTokenSave = new Map<String, CancelTokenSource>()

  constructor (options: CreateAxiosDefaults) {
    this.service = axios.create(options)
    this.service.interceptors.request.use(
      (config) => {
        console.log('request', config)
        // interceptors(config.headers)
        // const data = config.data
        // if (data) {
        //   if (config.method === 'put' || config.method === 'post') {
        //     const fd = new FormData()
        //     const keys = Object.keys(data)
        //     keys.forEach((key) => {
        //       if (typeof key === 'string') {
        //         const val: any = data[key]
        //         // 文件
        //         if (val instanceof File) {
        //           fd.append(key, val)
        //           return
        //         }
        //         // 如果还其他类型对象 在做判断
        //         // 数据对象
        //         if (typeof val === 'object') {
        //           fd.append(key, JSON.stringify(val))
        //           return
        //         }
        //         if (val !== undefined && val != null) {
        //           fd.append(key, val)
        //         }
        //       } else {
        //         console.warn('key of other types is not support')
        //       }
        //     })
        //     // config.headers.contentType = 'multipart/form-data'
        //     config.data = fd
        //   } else {
        //     Object.keys(data).forEach(key => {
        //       if (typeof data[key] === 'object') {
        //         data[key] = JSON.stringify(data[key])
        //       }
        //     })
        //     config.params = data
        //   }
        // }
        // console.debug(config)
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    // this.service.interceptors.response.use(
    //   (response) => Promise.resolve(response),
    //   (error) => {
    //     const { message } = error
    //     console.error(message)
    //     return Promise.reject(new RequestException(message))
    //   }
    // )
  }

  cancel (bid:string) {
    try {
      const token = this.cancelTokenSave.get(bid)
      if (token) {
        token.cancel()
        this.cancelTokenSave.delete(bid)
        return true
      } else {
        throw new RequestException('token is not exist')
      }
    } catch (e: any) {
      throw new RequestException(e.message)
    }
  }

  async action<T extends RequestAndResponse> (bid:string, url: T['url'], method: T['method'], params: T['Request'], headers:StringKeyRecord<any>, onUploadProgress?: (e: AxiosProgressEvent) => void, onDownloadProgress?: (e: AxiosProgressEvent) => void): Promise<T['Response']> {
    let requestData: FormData | undefined
    let requestParams: StringKeyRecord<any> | undefined
    if (params) {
      try {
        const keys = Object.keys(params)
        if (method === 'PUT' || method === 'POST') {
          const fd = new FormData()
          keys.forEach((key) => {
            if (typeof key === 'string') {
              const val: any = params[key]
              if (val instanceof File) {
                fd.append(key, val)
              } else if (val instanceof Blob) {
                fd.append(key, new File([val], 'unknown'))
              } else if (typeof val === 'object') {
                // 根据约定把blob转成file
                if (val.hasOwnProperty('jlinkBlobCovertFlag')) {
                  fd.append(key, new File([val.blob], val.filename))
                } else {
                  fd.append(key, JSON.stringify(val))
                }
              } else if (val !== undefined && val != null) {
                fd.append(key, val)
              } else {
                console.warn(`key of other types ${key} is not support`)
              }
            } else {
              console.warn(`key of other types ${key.toString()} is not support`)
            }
          })
          requestData = fd
        } else {
          keys.forEach(key => {
            if (typeof params[key] === 'object') {
              params[key] = JSON.stringify(params[key])
            }
          })
          requestParams = params
        }
      } catch (e:any) {
        throw new RequestException(e.message)
      }
    }
    this.cancelTokenSave.set(bid, axios.CancelToken.source())
    let result :AxiosResponse<Result<T['Response']>>
    try {
      result = await this.service({
        onUploadProgress,
        onDownloadProgress,
        cancelToken: this.cancelTokenSave.get(bid)?.token,
        url,
        method,
        data: requestData,
        headers,
        params: requestParams
      })
      this.cancelTokenSave.delete(bid)
    } catch (e:any) {
      this.cancelTokenSave.delete(bid)
      throw new ResponseException(e.message, e.code)
    }
    const response = result.data
    const code = response.code
    const message = response.message
    const resultValue = response.data
    if (code === 0) {
      return resultValue
    } else {
      console.log(url)
      throw new ResponseException(message, code)
    }
  }
}
