import { ref } from 'vue'
import AlsException from '@/common/errors/AlsException'
import { ExceptionEnum } from '@/common/errors/ExceptionEnum'
import DrcServiceBase from '@/common/mqtt/DrcServiceBase'
import JlinkBindThis from '@/common/annotate/JlinkBindThis'
import {DroneJoystick} from "@/common/mqtt/jlink/DrcServiceMqtt";

export type JoystickAxis = {
  type: 'axis',
  which: number
  axis: number
  value: number
}

type JoystickJhat = {
  type: 'hat',
  which: number
  jhat: number
  direct: 'centered' | 'up' | 'right' | 'down' | 'left'
}

type JoystickBall = {
  type: 'ball',
  which: number
  xrel: number
  yrel: number
}
type JoystickButton = {
  type: 'click',
  which: number
  button: number
  action: 'down' | 'up'
}

export default class JoyStickServiceBase extends DrcServiceBase {
  protected flyParams: DroneJoystick = {}
  protected zoomParams?: 'increase' | 'decrease'
  protected joystickWhichSave = new Set<number>()
  protected joystickButtonSave = ref<('up' | 'down')[]>([])
  protected joystickAxisSave = ref<number[]>([])
  protected joystickHatSave = ref<('centered' | 'up' | 'right' | 'down' | 'left')[]>([])
  protected joystickBallSave = ref<{ xrel: number, yrel: number } | undefined>()
  protected controlKeyPress = false

  constructor () {
    super()
    const _this = this
    this.addInterval('JoyStickServiceBaseFps', function () {
      if (_this.controlKeyPress) {
        const x = _this.flyParams.x
        const y = _this.flyParams.y
        const gp = [0.5, 0.5]
        if (x) {
          gp[0] = gp[0] + 0.5 * x
        }
        if (y) {
          gp[1] = gp[1] + 0.5 * -y
        }
        if (gp[0] !== 0.5 || gp[1] !== 0.5) {
          _this.cameraAim(gp)
        }
      } else if (_this.flyParams.seq !== undefined) {
        // console.log("flyParams", _this.flyParams)
        const param:DroneJoystick = {
          seq: _this.flyParams.seq++,
          y: _this.flyParams.x && 17 * _this.flyParams.x,
          x: _this.flyParams.y && 17 * -_this.flyParams.y,
          w: _this.flyParams.w && 90 * _this.flyParams.w,
          h: _this.flyParams.h && (-_this.flyParams.h > 0 ? (5 * -_this.flyParams.h) : (4 * -_this.flyParams.h))
        }

        _this.dispatchDroneControl(param)
      }
      if (_this.zoomParams) {
        if (_this.zoomParams === 'increase') {
          _this.zoomIncrease(2)
        } else {
          _this.zoomDecrease(2)
        }
      }
    }, 100)
  }

  destroy () {
    super.destroy()
  }

  protected makeButtonWatch (button: number, callback: (action: 'up' | 'down') => void) {
    this.watchSource(() => this.joystickButtonSave.value[button], function (value, oldValue, onCleanup) {
      callback(value)
    })
  }

  protected makeAxisWatch (axis: number, callback: (value: number) => void) {
    this.watchSource(() => this.joystickAxisSave.value[axis], function (value, oldValue, onCleanup) {
      callback(value)
    })
  }

  protected makeBallWatch (callback: (action: { xrel: number, yrel: number }) => void) {
    this.watchSource(() => this.joystickBallSave.value, function (value, oldValue, onCleanup) {
      if (value) {
        callback(value)
      }
    })
  }

  protected makeHatWatch (hat: number, callback: (direct: 'centered' | 'up' | 'right' | 'down' | 'left') => void) {
    this.watchSource(() => this.joystickHatSave.value[hat], function (value, oldValue, onCleanup) {
      callback(value)
    })
  }

  @JlinkBindThis
  handleJoyStickTrigger (e: CustomEvent<JoystickButton|JoystickBall|JoystickJhat|JoystickAxis>) {
    // console.log(e.detail)
    const result = e.detail
    // console.log(result)
    // return
    // this.joystickWhichSave.add(result.which)
    if (this.joystickWhichSave.size > 1) {
      throw new AlsException('摇杆来源冲突', 100, ExceptionEnum.INFO)
    }
    switch (result.type) {
      case 'click':
        this.joystickButtonSave.value[result.button] = result.action
        break
      case 'ball':
        this.joystickBallSave.value = { xrel: result.xrel, yrel: result.yrel }
        break
      case 'hat':
        this.joystickHatSave.value[result.jhat] = result.direct
        break
      case 'axis':
        this.joystickAxisSave.value[result.axis] = result.value
        break
    }
  }

  private flySave = { x: 0, y: 0, w: 0, h: 0 }

  protected flyAction (axisX?: number, axisY?: number, axisW?: number, axisH?: number) {
    if (axisX !== undefined) {
      const offsetX = axisX / (65535 / 2)
      this.flySave.x = offsetX.range(1, -1)
    }
    if (axisY !== undefined) {
      const offsetY = axisY / (65535 / 2)
      this.flySave.y = offsetY.range(1, -1)
    }
    if (axisW !== undefined) {
      const offsetW = axisW / (65535 / 2)
      this.flySave.w = offsetW.range(1, -1)
    }
    if (axisH !== undefined) {
      const offsetH = axisH / (65535 / 2)
      this.flySave.h = offsetH.range(1, -1)
    }
    this.covertToFlyParam()
  }

  private debounceValue = 0.2

  private transformDebounce (v: number) {
    // 这是一个v减去差值拉伸的函数
    if (v > this.debounceValue) {
      return ((v - this.debounceValue) * 10 / (10 - this.debounceValue * 10)).range(1, -1)
    } else if (v < -this.debounceValue) {
      return ((v + this.debounceValue) * 10 / (10 - this.debounceValue * 10)).range(1, -1)
    } else {
      return 0
    }
  }

  private transformFun (v: number) {
    // 这是一个曲线级接近v的函数
    if (v > 0) {
      return 1 - Math.sqrt(1 - Math.pow(v, 2))
    } else if (v < 0) {
      return Math.sqrt(1 - Math.pow(v, 2)) - 1
    } else {
      return 0
    }
  }

  private covertToFlyParam () {
    // console.log(this.flySave)
    // return
    if ((Math.abs(this.flySave.x) < this.debounceValue && Math.abs(this.flySave.y) < this.debounceValue && Math.abs(this.flySave.h) < this.debounceValue) && Math.abs(this.flySave.w) < 0.01) {
      console.log('fly', '回中')
      this.flyParams.seq = undefined
    } else {
      if (this.flyParams.seq === undefined) {
        this.flyParams.seq = 0
      }
      const x = this.transformDebounce(this.flySave.x)
      const y = this.transformDebounce(this.flySave.y)
      // const h = this.transformDebounce(this.flySave.h)
      const fx = this.transformFun(x)

      const fy = this.transformFun(y)
      const fw = this.transformFun(this.flySave.w)
      // const fh = this.transformFun(h)
      const fh = this.flySave.h
      this.flyParams.y = fy
      this.flyParams.x = fx
      this.flyParams.w = fw
      this.flyParams.h = fh
    }
  }
}
