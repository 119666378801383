import { reactive, ref } from 'vue'
import {
  inputBoxTextSet,
  psdkSpeakerPlay,
  speakerPlayModeSet,
  speakerPlayStop,
  speakerPlayVolumeSet,
  speakerReplay,
  widgetValueSet
} from '@/api/api.psdk'
import JlinkThrottle from '@/common/annotate/JlinkThrottle'
import NotifyException from '@/common/errors/NotifyException'
import JlinkUtils from '@/common/global/JlinkUtils'
import BaseHelper from '@/common/helper/BaseHelper'

type PsdkSpeakerValue = {
  psdkReady: number,
  psdkIndex: number
  objectKey?: string
  uiSource?: UiResourceConfigFormat,
  speaker?: PsdkSpeaker
  speakerPlayStartProgress?: SpeakerPlayStartProgress
  psdkName?: string
  values?: {
    index: number // 3:灯光 8:喊话音量 16:联动 19:灯光角度
    value: number
  }[]
  psdkSn?: string
  psdkVersion?: string
  psdkLibVersion?: string
}

export default class PsdkBase extends BaseHelper {
  private uiResourceZipPrefix = 'widget/cn/'
  private configPath = this.uiResourceZipPrefix + 'widget_config.json'
  private drone: DroneBaseVo
  private values = reactive<{
    // 用于多psdk设备的时候做删选 目前只支持单个设备
    currentPsdkIndex?: number
    device: NumberKeyRecord<PsdkSpeakerValue>
  }>({
    device: {}
  })

  setPsdkSpeakerPlayStartProgress (msg?: SpeakerPlayStartProgress) {
    if (msg) {
      const device = this.values.device[msg.psdkIndex]
      if (device) {
        device.speakerPlayStartProgress = msg
      }
    }
  }

  constructor (drone: DroneBaseVo) {
    super()
    this.drone = drone
  }

  getPsdKReadyDevices () {
    return Object.values(this.values.device).filterIf('psdkReady', 1)
  }

  getCurrentPsdKIndex () {
    return this.values.currentPsdkIndex
  }

  getCurrentPsdKDevice () {
    if (this.values.currentPsdkIndex !== undefined) {
      return this.values.device[this.values.currentPsdkIndex]
    } else {
      return undefined
    }
  }

  getCurrentPsdkSpeaker () {
    return this.getCurrentPsdKDevice()?.speaker
  }

  getCurrentPsdkSpeakerPlayProgress () {
    return this.getCurrentPsdKDevice()?.speakerPlayStartProgress
  }

  getCurrentPsdkPlayMode () {
    return this.getCurrentPsdkSpeaker()?.play_mode
  }

  getCurrentPsdkWorkMode () {
    return this.getCurrentPsdkSpeaker()?.work_mode
  }

  getCurrentPsdkPlayVolume () {
    return this.getCurrentPsdkSpeaker()?.play_volume
  }

  getCurrentPsdkSystemState () {
    return this.getCurrentPsdkSpeaker()?.system_state
  }

  getCurrentPsdkPlayFileId () {
    return this.getCurrentPsdkSpeaker()?.play_file_name
  }

  getCurrentPsdkUiResource () {
    const uiSource = this.getCurrentPsdKDevice()?.uiSource
    const result: StringKeyRecord<((UiResourceConfigFormatWidgetList | UiResourceConfigFormatWidgetScale | UiResourceConfigFormatWidgetSWitch | UiResourceConfigFormatWidgetButton) & {
      sortIndex: number
    })[]> = {}

    function getTypeResult (type: string) {
      if (!result[type]) {
        result[type] = []
      }
      return result[type]
    }

    const widget_list = uiSource ? [...uiSource.main_interface.widget_list, ...uiSource.config_interface.widget_list] : []

    if (this.isSpeakerLp12()) {
      widget_list.forEach(item => {
        if (item.widget_index === 0) {
          getTypeResult('工作模式设置').push({ sortIndex: 0, ...item })
        }
        if (item.widget_index === 3) {
          getTypeResult('探照灯设置').push({ sortIndex: 0, ...item, widget_name: '当前状态' })
        }
        if (item.widget_index === 4) {
          getTypeResult('探照灯设置').push({ sortIndex: 3, ...item, widget_name: '云台跟随' })
        }
        if (item.widget_index === 5) {
          getTypeResult('TTS设置').push({ sortIndex: 0, ...item })
        }
        // if(item.widget_index===6){
        //   getTypeResult("工作模式设置").push({sortIndex:2,...item })
        // }
        if (item.widget_index === 7) {
          getTypeResult('TTS设置').push({ sortIndex: 1, ...item })
        }
        // if(item.widget_index===8){
        //   getTypeResult("工作模式设置").push({sortIndex:1,...item,widget_name:"播放音量"})
        // }
        if (item.widget_index === 19 && !this.isCurrentPsdkFlightFollow()) {
          getTypeResult('探照灯设置').push({ sortIndex: 2, ...item, widget_name: '灯光角度' })
        }
        if (item.widget_index === 20) {
          getTypeResult('探照灯设置').push({ sortIndex: 1, ...item, widget_name: '灯光亮度' })
        }
      })
    } else if (this.isSpeakerAB125D()) {
      widget_list.forEach(item => {
        if (item.widget_index === 3) {
          getTypeResult('警示灯设置').push({ sortIndex: 1, ...item })
        }
        if (item.widget_index === 4) {
          getTypeResult('TTS设置').push({ sortIndex: 1, ...item })
        }
        if (item.widget_index === 6) {
          getTypeResult('TTS设置').push({ sortIndex: 0, ...item })
        }
      })
    } else {
      // TODO 其他品牌的喊话器暂未实现
      widget_list.forEach(item => {
        getTypeResult('所有设置').push({ sortIndex: 0, ...item })
      })
    }

    return Object.keys(result).map(key => {
      return { label: key, actions: result[key].sortBy('sortIndex', 'asc') }
    })
  }

  // dock1用的成致喊话器
  isSpeakerLp12 () {
    const psdKDevice = this.getCurrentPsdKDevice()
    return psdKDevice?.psdkName === 'LP12'
  }

  // dock1用的成致喊话器
  isSpeakerAB125D () {
    const psdKDevice = this.getCurrentPsdKDevice()
    return psdKDevice?.psdkName === 'AB125D'
  }

  isCurrentPsdkRealTimeSpeaker () {
    const psdKDevice = this.getCurrentPsdKDevice()
    if (this.isSpeakerLp12()) {
      return psdKDevice && (psdKDevice.values?.findBy('index', 0)?.value) === 1
    } else {
      console.warn('其他喊话器暂未实现该功能')
    }
    return false
  }

  isCurrentPsdkFlightFollow () {
    const psdKDevice = this.getCurrentPsdKDevice()
    if (this.isSpeakerLp12()) {
      return psdKDevice && (psdKDevice.values?.findBy('index', 4)?.value) === 1
    } else {
      console.warn('其他喊话器暂未实现该功能')
    }
    return false
  }

  getCurrentPsdkWeightValues () {
    return this.getCurrentPsdKDevice()?.values
  }

  @JlinkThrottle(500, true, true)
  setPsdkWidgetValues (msg?: PsdkWidgetValuesReceive) {
    console.log('currentValue', 'psdkFloatTextReceiveHandler', msg)
    if (msg) {
      for (const value of msg) {
        let target = this.values.device[value.psdk_index]
        if (!target) {
          this.values.device[value.psdk_index] = {
            psdkReady: 1,
            psdkIndex: value.psdk_index,
          }
        }
        target = this.values.device[value.psdk_index]
        target.speaker = value.speaker
        target.psdkName = value.psdk_name

        target.values = value.values
        target.psdkSn = value.psdk_sn
        target.psdkVersion = value.psdk_version
        target.psdkLibVersion = value.psdk_lib_version
      }
    }
  }

  async setPsdkUiResourceUploadReceive (msg?: PsdkUiResourceUploadReceive) {
    if (msg) {
      for (const configElement of msg) {
        let target = this.values.device[configElement.psdk_index]
        if (target) {
          target.psdkReady = configElement.psdk_ready
          target.psdkIndex = configElement.psdk_index
          target.objectKey = configElement.object_key
        } else {
          this.values.device[configElement.psdk_index] = {
            psdkReady: configElement.psdk_ready,
            psdkIndex: configElement.psdk_index,
            objectKey: configElement.object_key
          }
        }
        target = this.values.device[configElement.psdk_index]
        if (!target.uiSource) {
          const url = await window.$oss.getSignUrl(target.objectKey!)
          const result = await JlinkUtils.zip.unMakeZip(url, { [this.configPath]: 'string' })
          const resourceUrls: StringKeyRecord<string> = Object.create({})
          let config: string | undefined
          const valueKeys = Object.keys(result) as string[]
          for (const key of valueKeys.filter(i => i.startsWith(this.uiResourceZipPrefix))) {
            if (key === this.configPath) {
              config = result[key]
            } else {
              const image = key.replace(this.uiResourceZipPrefix, '')
              resourceUrls[image] = URL.createObjectURL(result[key])
            }
          }
          if (config) {
            Object.keys(resourceUrls).forEach(i => {
              config = config!.replaceAll(i as string, resourceUrls[i])
              console.log(i, resourceUrls[i])
              console.log(JSON.parse(config))
            })
            target.uiSource = JSON.parse(config)
          }
        }
      }
      if (!this.values.currentPsdkIndex || !Object.keys(this.values.device).includes(this.values.currentPsdkIndex)) {
        this.values.currentPsdkIndex = Object.keys(this.values.device).first()
      }
    }
  }

  setOnline (d?: MqttDroneOnlineResult) {
    if (!d?.aircraftOnline) {
      this.values.device = {}
    }
  }

  destory () {
    super.destroy()
    this.values.device = {}
  }

  private checkPsdk (psdkIndex?: number) {
    if (!psdkIndex || this.values.device[psdkIndex] === undefined) {
      throw new NotifyException(`psdk${psdkIndex}未准备就绪`, 'error')
    }
  }

  async widgetValueSet (index: number, value: number | undefined) {
    this.checkPsdk(this.getCurrentPsdKIndex())
    return await widgetValueSet({
      gatewaySn: this.drone.gatewaySerialNumber,
      psdkIndex: this.getCurrentPsdKIndex()!!,
      index,
      value
    })
  }

  async speakerPlayVolumeSet (playVolume: number) {
    this.checkPsdk(this.getCurrentPsdKIndex())
    return await speakerPlayVolumeSet({
      gatewaySn: this.drone.gatewaySerialNumber,
      psdkIndex: this.getCurrentPsdKIndex()!!,
      playVolume,
    })
  }

  async speakerPlayModeSet (mode: 0 | 1) {
    this.checkPsdk(this.getCurrentPsdKIndex())
    return await speakerPlayModeSet({
      gatewaySn: this.drone.gatewaySerialNumber,
      psdkIndex: this.getCurrentPsdKIndex()!!,
      mode,
    })
  }

  async speakerReplayOrStop (play: boolean) {
    this.checkPsdk(this.getCurrentPsdKIndex())
    if (play) {
      return await speakerReplay({
        gatewaySn: this.drone.gatewaySerialNumber,
        psdkIndex: this.getCurrentPsdKIndex()!!,
      })
    } else {
      return await speakerPlayStop({
        gatewaySn: this.drone.gatewaySerialNumber,
        psdkIndex: this.getCurrentPsdKIndex()!!,
      })
    }
  }

  async inputBoxTextSet (text: string) {
    this.checkPsdk(this.getCurrentPsdKIndex())
    return await inputBoxTextSet({
      gatewaySn: this.drone.gatewaySerialNumber,
      psdkIndex: this.getCurrentPsdKIndex()!!,
      text
    })
  }

  async psdkSpeakerPlay (fileId: string) {
    this.checkPsdk(this.getCurrentPsdKIndex())
    return await psdkSpeakerPlay({
      gatewaySn: this.drone.gatewaySerialNumber,
      psdkIndex: this.getCurrentPsdKIndex()!!,
      fileId
    })
  }
}
