import { onUnmounted } from 'vue'
import mitt from '@/common/mitt/Emitter'
declare type Handler<T = any> = (event: T) => void;

const emitter = mitt()

export function mittOn<T> (type: string, callback: Handler<T>) {
  // @ts-ignore
  emitter.on(type, callback)
}

export function mittOff<T> (type: string, callback: Handler<T>) {
  // @ts-ignore
  emitter.off(type, callback)
}

export function mittEmit<T> (type: string, body: T) {
  emitter.emit<T>(type, body)
}

// 错误码处理
export function mittError (code: Error) {
  emitter.emit<Error>('error', code)
}

export function watchError (callback: Handler<number>, useInVue:boolean = true) {
  makeWatch('error', callback, useInVue)
}

export function mittLoading (loading: boolean) {
  emitter.emit('loading', { loading })
}
export function watchLoading (callback: Handler<{ loading: boolean }>) {
  makeWatch('loading', callback)
}

export function mittWaylineJobUpdate (data: { waylinejob: WaylineJobVo, action: 'add' | 'update' }) {
  emitter.emit('WaylineJobUpdate', data)
}
export function watchWaylineJobUpdate (callback: Handler<{ waylinejob: WaylineJobVo, action: 'add' | 'update' }>) {
  makeWatch('WaylineJobUpdate', callback)
}

export function mittDroneFileUpload (data: UploadFileVo) {
  emitter.emit('DroneFileUpload', data)
}
export function watchDroneFileUpload (callback: Handler<UploadFileVo>) {
  makeWatch('DroneFileUpload', callback)
}

export function mittWaylineSelected (waylineId:string) {
  emitter.emit('WaylineSelected', waylineId)
}
export function watchWaylineSelected (callback: Handler<string>) {
  makeWatch('WaylineSelected', callback)
}

export function watchLiveStreamPlay (callback: Handler<{sn:string, play:boolean}>) {
  makeWatch('LiveStreamPlay', callback)
}

export function mittWaylineJobWrapper (type:'drone'|'wayline') {
  emitter.emit('WaylineJobWrapper', type)
}
export function watchWaylineJobWrapper (callback: Handler<'drone'|'wayline'>) {
  makeWatch('WaylineJobWrapper', callback)
}



export function mittJoystickLayoutChange (data: number) {
  emitter.emit('JoystickLayoutChange', data)
}

export function watchJoystickLayoutChange (callback: Handler<number>) {
  makeWatch('JoystickLayoutChange', callback)
}

export function mittTargetLocation (data: JlinkLocation) {
  emitter.emit('TargetLocation', data)
}

export function watchTargetLocationChange (callback: Handler<JlinkLocation>) {
  makeWatch('TargetLocation', callback)
}

function makeWatch (type:string, fun:Handler, useInVue:boolean = true) {
  emitter.off(type, fun)
  emitter.on(type, fun)
  if (useInVue) {
    onUnmounted(function () {
      emitter.off(type, fun)
    })
  }
}

export function mittClear () {
  emitter.all.clear()
}
