import { reactive, ref } from 'vue'
import {
  psdkFileDelete,
  psdkFileListByQuery,
  psdkFileRename,
  psdkPlayFileUpload,
  psdkTtsFileUpload,
} from '@/api/api.psdk'
import PsdkBase from '@/common/mqtt/jlink/PsdkBase'

export default class PsdkSpeaker extends PsdkBase {
  actionTypes = ref<'upload' | 'record' | 'tts'>()
  fileRemoteParam = reactive<{
    orderBy?: 'desc' | 'asc',
    fromType?: 0 | 1 | 2,
    searchKey?: string,
  }>({
    orderBy: 'desc'
  })

  currentSpeakerFile = ref<PsdkPlayFileVo>()
  currentSpeakerTestFile = ref<PsdkPlayFileVo&{
    progress?:{
      currentTime:number
      duration:number
    }
    timeoutKey?:number

  }>()

  destory () {
    super.destory()
    this.currentSpeakerTestFile.value = undefined
    this.currentSpeakerFile.value = undefined
  }

  setFileRemoteFromType (fromType?: 0 | 1 | 2) {
    if (fromType !== this.fileRemoteParam.fromType) {
      this.fileRemoteParam.fromType = fromType
      return true
    } else {
      return false
    }
  }

  setFileRemoteOrderBy (orderBy?: 'desc' | 'asc') {
    if (orderBy !== this.fileRemoteParam.orderBy) {
      this.fileRemoteParam.orderBy = orderBy || 'desc'
      return true
    } else {
      return false
    }
  }

  setFileRemotesSearchKey (searchKey?: string) {
    if (searchKey !== this.fileRemoteParam.searchKey) {
      this.fileRemoteParam.searchKey = searchKey
      return true
    } else {
      return false
    }
  }

  async psdkFileListByQuery (param: { page: number, size: number }) {
    return await psdkFileListByQuery({ ...param, ...this.fileRemoteParam })
  }

  async psdkFileRename (fileId: string, fileName: string) {
    return await psdkFileRename({
      fileId,
      fileName
    })
  }

  async psdkPlayFileUpload (file: File, from: 0 | 1) {
    return await psdkPlayFileUpload({
      file,
      from
    })
  }

  async psdkFileDelete (fileId: string) {
    await psdkFileDelete({ fileId })
    if (this.currentSpeakerFile.value?.fileId === fileId) {
      this.currentSpeakerFile.value = undefined
    }
  }

  async psdkTtsFileUpload (tts: string) {
    await psdkTtsFileUpload({ tts })
    this.actionTypes.value = undefined
  }
}
