import { drcEnter } from '@/api/api.drc'
import JlinkTask from '@/common/global/JlinkTask'
import BasicMqtt from '@/common/workers/components/BasicMqtt'
import BaseHelper from '@/common/helper/BaseHelper'
import MqttException from '@/common/errors/MqttException'
import { ref } from 'vue'

export enum DRC_METHOD {
  HEART_BEAT = 'heart_beat',
  DRONE_CONTROL = 'drone_control', // 飞行控制-虚拟摇杆
  DRONE_EMERGENCY_STOP = 'drone_emergency_stop', // 急停
  OSD_INFO_PUSH = 'osd_info_push', // 高频osd信息上报
  HSI_INFO_PUSH = 'hsi_info_push', // 避障信息上报
  DELAY_TIME_INFO_PUSH = 'delay_info_push', // 图传链路延时信息上报
}

export interface DelayInfoPush {
  'method': 'delay_info_push',
  'timestamp': number,
  'data': {
    'sdr_cmd_delay': number,
    'liveview_delay_list': {
      'video_id': string,
      'liveview_delay_time': number
    } []
  }
}

export interface DroneJoystick {
  x?: number; // 水平方向速度，正值为A指令  负值为D指令 单位：m/s
  y?: number; // 前进后退方向速度，正值为W指令  负值为S指令 单位：m/s
  h?: number;// 上下高度值，正值为上升指令  负值为下降指令 单位：m
  w?: number; // 机头角速度，正值为顺时针，负值为逆时针 单位：degree/s   （web端暂无此设计）
  seq?: number; // 从0计时
}

export interface OsdInfoPush {
  method: 'osd_info_push'
  timestamp: number
  data: {
    attitude_head: number//	飞行器姿态 head 角	double	{"unit":"度"}
    latitude: number//	飞行器纬度	double	{"unit":"度"}
    longitude: number//	飞行器经度	double	{"unit":"度"}
    height: number//	飞行器海拔高度	double	{"unit":"度"}
    speed_x: number//	当前飞行器 X 坐标方向的速度	double	{"unit":"米/秒"}
    speed_y: number//	当前飞行器 Y 坐标方向的速度	double	{"unit":"米/秒"}
    speed_z: number//	当前飞行器 Z 坐标方向的速度	double	{"unit":"米/秒"}
    gimbal_pitch: number//	云台pitch角	double	{"unit":"度"}
    gimbal_roll: number//	云台roll角	double	{"unit":"度"}
    gimbal_yaw: number// 云台yaw角	double	{"unit":"度"}
  }
}

export interface HsiInfoPush {
  method: 'hsi_info_push',
  timestamp: number,
  data: {
    'up_distance': number,
    'down_distance': number,
    around_distances: number[],
    'up_enable': boolean,
    'up_work': boolean,
    'down_enable': boolean,
    'down_work': boolean,
    'left_enable': boolean,
    'left_work': boolean,
    'right_enable': boolean,
    'right_work': boolean,
    'front_enable': boolean,
    'front_work': boolean,
    'back_enable': boolean,
    'back_work': boolean,
    'vertical_enable': boolean,
    'vertical_work': boolean,
    'horizontal_enable': boolean,
    'horizontal_work': boolean
  }
}

export default class DrcServiceMqtt extends BaseHelper {
  private mqtt: BasicMqtt|undefined
  private connecting = false
  private readonly topicUp: string
  private readonly topicDown: string
  private drcReq = 0
  private drcState: number | undefined
  private readonly gatewaySn: string
  private readonly handler: (msg: OsdInfoPush | HsiInfoPush | DelayInfoPush) => void
  private testData = ref()

  constructor (gatewaySn: string, handler: (msg: OsdInfoPush | HsiInfoPush | DelayInfoPush) => void) {
    super()
    this.gatewaySn = gatewaySn
    this.handler = handler
    this.topicUp = `thing/product/${this.gatewaySn}/drc/up`
    this.topicDown = `thing/product/${this.gatewaySn}/drc/down`
  }

  private connected () {
    return this.mqtt?.getConnected()
  }

  updateDrcCode (drcState: 0 | 1 | 2) {
    this.drcState = drcState
    if (this.drcState === 2) {
      if (!this.connected() && !this.connecting) {
        this.connection().then()
      }
    } else if (this.drcState === 0) {
      if (this.connected()) {
        this.disconnection().then()
      }
    }
  }

  private async connection (): Promise<void> {
    try {
      this.connecting = true
      const res = await drcEnter({ gatewaySn: this.gatewaySn })
      if (this.gatewaySn && res.clientId) {
        if (!this.mqtt) {
          this.mqtt = new BasicMqtt()
        }
        await this.mqtt.unregister(this.topicUp, this.handler)
        this.deleteInterval('mqttHeart')
        await this.mqtt.connection(res)
        await this.mqtt.register(this.topicUp, 0, this.handler)
        this.drcReq = 0
        const _this = this
        this.addInterval('mqttHeart', function () {
          // 发送心跳
          const body = { method: DRC_METHOD.HEART_BEAT, data: { seq: _this.drcReq++, timestamp: Date.now() }, }
          _this.mqtt?.send(_this.topicDown, JSON.stringify(body), { qos: 1 })
        }, 2000)
      } else {
        console.error('飞机信息不存在或者用户未获得指令飞行权限')
      }
    } catch (e: any) {
    } finally {
      this.connecting = false
    }
  }

  private async disconnection (): Promise<void> {
    this.deleteInterval('mqttHeart')
    this.connecting = false
    await this.mqtt?.unregister(this.topicUp, this.handler)
    await this.mqtt?.disconnection()
  }

  async dispatchEmergencyStop () {
    if (this.drcState !== 2) {
      throw new MqttException('drc未连接')
    }
    if (!this.mqtt) {
      throw new MqttException('mqtt 实例不存在')
    }
    const body = {
      method: DRC_METHOD.DRONE_EMERGENCY_STOP,
      data: {}
    }
    await this.mqtt.send(this.topicDown, JSON.stringify(body), { qos: 1 })
  }

  async dispatchJoystick (data: DroneJoystick) {
    // @ts-ignore
    data.id = this.id
    this.testData.value = data
    if (this.drcState !== 2) {
      throw new MqttException('drc未连接')
    }
    if (!this.mqtt) {
      throw new MqttException('mqtt 实例不存在')
    }
    const body = { method: DRC_METHOD.DRONE_CONTROL, data }
    if (!this.connected()) {
      if (!this.connecting) {
        if (this.drcState === 2) {
          this.connection().then()
        }
      }
    } else {
     this.mqtt.send(this.topicDown, JSON.stringify(body), { qos: 0 }).then()
    }
  }

  async destroy () {
    super.destroy()
    await this.disconnection()
  }
}
