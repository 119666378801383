import JlinkTransform from '@/common/global/JlinkTransform'
import JlinkUtils from '@/common/global/JlinkUtils'
import NotifyException from '@/common/errors/NotifyException'
import {pauseFlightTask, recoveryFlightTask, returnHome, returnHomeCancel} from '@/api/api.flighttask'
import {flyToPoint, flyToPointStop, takeOffToPoint, updateToPoint} from '@/api/api.drc'
import {MAP_COORDINATE} from '@/common/global/JlinkValues'
import {DroneJoystick} from '@/common/mqtt/jlink/DrcServiceMqtt'
import OsdAircraftValues from '@/common/mqtt/jlink/OsdAircraftValues'
import {DOCK_MODE_CODE} from "@/common/enum/DEVICE_ENUMS";

export default class OsdGatewayValues extends OsdAircraftValues {
  private dockFlightTaskProcessStepEnum = JSON.parse('{"0":"初始状态","1":"启动前检查，飞行器是否在执行航线中","2":"启动前检查，机场是否退出工作模式","3":"启动前检查，航线执行中","4":"启动前检查，返航中","5":"航线执行进入准备状态，开始等待任务下发","6":"机场进入工作状态","7":"进入开机检查准备工作和开盖准备工作","8":"等待飞行系统准备就绪，推送连接建立","9":"等待 RTK 源监听有值上报","10":"检查 RTK 源是否是机场源，如果不是要重新设置","11":"等待飞行控制权通知","12":"机场无控制权，抢夺行器控制权","13":"获取最新 KMZ URL","14":"下载 KMZ","15":"KMZ 上传中","16":"染色配置","17":"飞行器起飞参数设置，备降点设置，起飞高度设置，染色设置","18":"飞行器 flyto 起飞参数设置","19":"Home 点设置","20":"触发执行航线","21":"航线执行中","22":"进入返航的检查准备工作","23":"飞行器降落机场","24":"降落以后的关盖","25":"机场退出工作模式","26":"机场异常恢复","27":"机场上传飞行系统日志","28":"相机录像状态检查","29":"获取媒体文件数量","30":"机场起飞开盖的异常恢复","31":"通知任务结果","32":"任务执行完成，通过配置文件配置，是否进行主动 log 拉取","33":"日志列表拉取 - 飞行器列表","34":"日志列表拉取 - 拉取机场列表","35":"日志列表拉取 - 上传日志列表结果","36":"日志拉取-拉取飞行器日志","37":"日志拉取-拉取机场日志","38":"日志拉取-压缩飞行器日志","39":"日志拉取-压缩机场日志","40":"日志拉取-上传飞行器日志","41":"日志拉取-上传机场日志","42":"日志拉取-通知结果","0xFFFD":"结束后等待服务回包","0xFFFE":"无具体状态","0xFFFFF":"UNKNOWN"}')

  getDockHmsInfo () {
    return this.osd?.getDockHmsValue()?.map(JlinkTransform.dockOsd.translateHms)
  }

  /* 任务进度回调 */
  getFlightTaskJobType () {
    return this.osd?.getDockFlightTaskProcessValue()?.jobType
  }

  getFlightTaskCurrentStep () {
    return this.osd?.getDockFlightTaskProcessValue()?.currentStep
  }

  getFlightTaskStatus () {
    return this.osd?.getDockFlightTaskProcessValue()?.taskStatus
  }

  getFlightTaskCurrentWayPointIndex () {
    return this.osd?.getDockFlightTaskProcessValue()?.currentWayPointIndex
  }

  getFlightTaskWayline () {
    const target = this.osd?.getFlightWaylineSave()
    const taskId = this.getFlightTaskId()
    if (taskId && target) {
      return target[taskId]
    }
  }

  getFlightTaskId () {
    return this.osd?.getDockFlightTaskProcessValue()?.taskId
  }

  getFlightTaskPercent () {
    return this.osd?.getDockFlightTaskProcessValue()?.percent
  }

  getFlightTaskRemainingTime () {
    return this.osd?.getDockFlightTaskProcessValue()?.remainingTime
  }

  getFlightTaskRemainingDistance () {
    return this.osd?.getDockFlightTaskProcessValue()?.remainingDistance
  }

  getFlightTaskCurrentStepZh () {
    const step = this.getFlightTaskCurrentStep()
    return step ? (this.dockFlightTaskProcessStepEnum[step] || 'UNKNOWN') : 'UNKNOWN'
  }

  getDockFlightTaskStatus () {
    return this.osd?.getDockFlightTaskProcessValue()?.taskStatus
  }

  isDockFlightTaskInProcess () {
    return this.getDockFlightTaskStatus() === 2
  }

  isDockFlightTaskInPause () {
    return this.getDockFlightTaskStatus() === 3
  }

  getGatewaySn () {
    return this.osd?.getDockOsdValue()?.gatewaySn
  }

  getDockFirmwareVersion () {
    return this.osd?.getDockOsdValue()?.firmwareVersion
  }

  getDockNetTypeZh () {
    return JlinkTransform.dockOsd.toNetworkTypeDesc(this.getDockNetType())
  }

  getDockAircraftInDock () {
    return this.osd?.getDockOsdValue()?.aircraftInDock
  }

  getDockAircraftInDockZh () {
    return JlinkTransform.dockOsd.toAircraftInDockDesc(this.getDockAircraftInDock())
  }

  getDockAircraftBatteryState () {
    return this.osd?.getDockOsdValue()?.aircraftBatteryState
  }

  getDockWirelessLinkSdrFreqBand () {
    return this.osd?.getDockOsdValue()?.wirelessLinkSdrFreqBand
  }

  getDockAircraftBatteryStateZh () {
    return JlinkTransform.dockOsd.toAircraftBatteryStateDesc(this.getDockAircraftBatteryState())
  }

  getDockNetRate () {
    const target = this.osd?.getDockOsdValue()?.networkRate
    return target && (target * 1024)
  }

  getDockNetRateUnit (f: number = 2, unit: string) {
    return this.getDockNetRate()?.asByteSizeFormat(f) + unit
  }

  getDockNetType () {
    return this.osd?.getDockOsdValue()?.networkType
  }

  getDockBatteryStoreMode () {
    return this.osd?.getDockOsdValue()?.batteryStoreMode
  }

  getDockBatteryStoreModeZh () {
    return JlinkTransform.dockOsd.toBatteryStoreModeDesc(this.getDockBatteryStoreMode())
  }

  getDockAlarmState () {
    return this.osd?.getDockOsdValue()?.alarmState
  }

  getDockAlarmStateZh () {
    return JlinkTransform.dockOsd.toAlarmStateDesc(this.getDockAlarmState())
  }

  getDockEmergencyStopState () {
    return this.osd?.getDockOsdValue()?.emergencyStopState
  }

  getDockEmergencyStopStateZh () {
    return JlinkTransform.dockOsd.toEmergencyStopStateDesc(this.getDockEmergencyStopState())
  }

  getDockAlternateLandPointIsConfigured () {
    return this.osd?.getDockOsdValue()?.alternateLandPointIsConfigured
  }

  getDockAlternateLandPointIsConfiguredZh () {
    return JlinkTransform.dockOsd.toAlternateLandPointIsConfigured(this.getDockAlternateLandPointIsConfigured())
  }

  getDockWirelessLinkFourGFreqBand () {
    return this.osd?.getDockOsdValue()?.wirelessLinkFourGFreqBand
  }

  getDockWirelessLinkSdrQuality () {
    return this.osd?.getDockOsdValue()?.wirelessLinkSdrQuality
  }

  getDockWirelessLinkFourGQuality () {
    return this.osd?.getDockOsdValue()?.wirelessLinkFourGQuality
  }

  getDockWirelessLinkFourGUavQuality () {
    return this.osd?.getDockOsdValue()?.wirelessLinkFourGUavQuality
  }

  getDockWirelessLinkFourGGndQuality () {
    return this.osd?.getDockOsdValue()?.wirelessLinkFourGGndQuality
  }

  getDockPositionGpsNumber () {
    return this.osd?.getDockOsdValue()?.positionGpsNumber
  }

  getDockPositionRtkNumber () {
    return this.osd?.getDockOsdValue()?.positionRtkNumber
  }

  getDockWirelessLinkQuality () {
    return this.getDockWirelessLinkWorkMode() === 0 ? this.getDockWirelessLinkSdrQuality() : this.getDockWirelessLinkWorkMode() === 1 ? this.getDockWirelessLinkFourGQuality() : undefined
  }

  getDockMode () {
    return this.osd?.getDockOsdValue()?.modeCode
  }

  getDockModeZh () {
    return JlinkTransform.dockOsd.toDockModeCodeDesc(this.getDockMode())
  }

  getDockCoverState () {
    return this.osd?.getDockOsdValue()?.coverState
  }

  getDockCoverStateZh () {
    return JlinkTransform.dockOsd.toCoverStateDesc(this.getDockCoverState())
  }

  getDockAircraftBatteryMaintenanceState () {
    return this.osd?.getDockOsdValue()?.aircraftBatteryMaintenanceState
  }

  getDockPutterState () {
    return this.osd?.getDockOsdValue()?.putterState
  }

  getDockPutterStateZh () {
    return JlinkTransform.dockOsd.toCoverStateDesc(this.getDockPutterState())
  }

  getDockSupplementLightState () {
    return this.osd?.getDockOsdValue()?.supplementLightState
  }

  getDockSupplementLightStateZh () {
    return JlinkTransform.dockOsd.toSupplementLightStateDesc(this.getDockSupplementLightState())
  }

  getDockBackupBatterySwitch () {
    return this.osd?.getDockOsdValue()?.backupBatterySwitch
  }

  getDockBackupBatterySwitchZh () {
    return JlinkTransform.dockOsd.toBackupBatterySwitch(this.getDockBackupBatterySwitch())
  }

  isDockRemoteDebugMode () {
    return this.getDockMode() === DOCK_MODE_CODE.REMOTE_DEBUG
  }

  isDockIdleMode () {
    return this.getDockMode() === DOCK_MODE_CODE.IDLE
  }
  isDockWorkingMode () {
    return this.getDockMode() === DOCK_MODE_CODE.WORKING
  }

  getDockWirelessLinkWorkMode () {
    return this.osd?.getDockOsdValue()?.wirelessLinkLinkWorkMode
  }

  getDockWirelessLinkWorkModeIcon () {
    const workMode = this.getDockWirelessLinkWorkMode()
    switch (workMode) {
      case 0:
        return { icon: 'svg-wireless-sdr', style: '' }
      case 1:
        return { icon: 'svg-wireless-4g', style: '' }
      default:
        return { icon: 'svg-wireless-sdr', style: 'danger' }
    }
  }

  getDockWirelessLinkQualityIcon () {
    const q = this.getDockWirelessLinkQuality() || 0
    const icon = `svg-signal-${q}`
    if (q > 3) {
      return { icon, style: 'full' }
    }
    if (q >= 2) {
      return { icon, style: 'warn' }
    }
    return { icon, style: 'danger' }
  }

  getDockWirelessLinkLinkWorkModeZh () {
    return JlinkTransform.dockOsd.toWirelessLinkLinkWorkModeDesc(this.getDockWirelessLinkWorkMode())
  }

  isDockSDRWirelessLink () {
    return this.getDockWirelessLinkWorkMode() === 0
  }

  isDockSDRWirelessLinkConnected () {
    return this.getDockSDRWirelessLinkState() === 1
  }

  getDockSDRWirelessLinkState () {
    return this.osd?.getDockOsdValue()?.wirelessLinkSdrLinkState
  }

  getDockSDRWirelessLinkStateZh () {
    return JlinkTransform.dockOsd.toWirelessLinkStateDesc(this.getDockSDRWirelessLinkState())
  }

  getDockAirConditionerMode () {
    return this.osd?.getDockOsdValue()?.airConditionerMode
  }

  getDockAirConditionerModeZh () {
    return JlinkTransform.dockOsd.toAirConditionerModeDesc(this.getDockAirConditionerMode())
  }

  isDock4GWirelessLink () {
    return this.getDockWirelessLinkWorkMode() === 1
  }

  isDock4GWirelessLinkConnected () {
    return this.osd?.getDockOsdValue()?.wirelessLinkFourGLinkState === 1
  }

  getDock4GWirelessLinkStateZh () {
    return JlinkTransform.dockOsd.toWirelessLinkStateDesc(this.osd?.getDockOsdValue()?.wirelessLinkFourGLinkState)
  }

  getDockRainZh () {
    return JlinkTransform.dockOsd.toRainFallDesc(this.osd?.getDockOsdValue()?.rainfall)
  }

  getDockAccTimeUnit (f: number = 1) {
    return this.osd?.getDockOsdValue()?.accTime.asBigTimeFormat(f)
  }

  getDockStorageTotal () {
    const target = this.osd?.getDockOsdValue()?.storageTotal
    return target && (target * 1024)
  }

  getDockStorageUsed () {
    const target = this.osd?.getDockOsdValue()?.storageUsed

    return target && (target * 1024)
  }

  getDockStorageUsedUnit (f: number = 1) {
    return this.getDockStorageUsed()?.asByteSizeFormat(f)
  }

  getDockStorageTotalUnit (f: number = 1) {
    return this.getDockStorageTotal()?.asByteSizeFormat(f)
  }

  getDockMediaRemainUploadUnit (unit: string = '个') {
    return this.osd?.getDockOsdValue()?.mediaRemainUpload.asBigNumFormat(0, unit)
  }

  getDockEnvironmentTemperatureUnit (f: number = 1, unit: string = '°C') {
    return this.osd?.getDockOsdValue()?.environmentTemperature.toFixedCustom(f) + unit
  }

  getDockBackupBatteryTemperatureUnit (f: number = 1, unit: string = '°C') {
    return this.osd?.getDockOsdValue()?.backupBatteryTemperature.toFixedCustom(f) + unit
  }

  getDockTemperatureUnit (f: number = 1, unit: string = '°C') {
    return this.osd?.getDockOsdValue()?.temperature.toFixedCustom(f) + unit
  }

  getDockWindSpeedUnit (f: number = 2, unit: string = 'm/s') {
    return this.osd?.getDockOsdValue()?.windSpeed?.toFixedCustom(f) + unit
  }

  getDockJobNumberUnit (unit: string = '次') {
    return this.osd?.getDockOsdValue()?.jobNumber.asBigNumFormat(0, unit)
  }

  getDockElectricSupplyVoltageUnit (unit: string = 'V') {
    return this.osd?.getDockOsdValue()?.electricSupplyVoltage + unit
  }

  getDockBackupBatteryVoltage () {
    const target = this.osd?.getDockOsdValue()?.backupBatteryVoltage
    return target && (target / 1000)
  }

  getDockBackupBatteryVoltageUnit (unit: string = 'V') {
    return this.getDockBackupBatteryVoltage() + unit
  }

  getDockWorkingVoltage (unit: string = 'V') {
    const target = this.osd?.getDockOsdValue()?.workingVoltage
    return target && (target / 1000)
  }

  getDockWorkingVoltageUnit (unit: string = 'V') {
    return this.getDockWorkingVoltage() + unit
  }

  getDockActivationTimeZh () {
    return JlinkUtils.date.parseTime(this.osd?.getDockOsdValue()?.activationTime)
  }

  getDockFirstPowerOnTimeZh () {
    return JlinkUtils.date.parseTime(this.osd?.getDockOsdValue()?.firstPowerOn)
  }

  getDockLng () {
    return this.osd?.getDockOsdValue()?.lng
  }

  getDockLat () {
    return this.osd?.getDockOsdValue()?.lat
  }

  getDockHeight () {
    return this.osd?.getDockOsdValue()?.height
  }

  isDockTakeOffAble () {
    if (this.osd?.getGatewayOnline() && this.getDockMode() === 0) {
      if (this.osd?.getAircraftOnline()) {
        return this.getDockMode() === 0
      } else {
        return true
      }
    } else {
      return false
    }
  }

  getDockDrcState () {
    return this.osd?.getDockOsdValue()?.drcState
  }

  isDockDrcConnected () {
    return this.getDockDrcState() === 2
  }

  isAircraftReturning () {
    return this.getAircraftMode() === 9
  }

  async pauseOrRecoveryFlightTask (catchIgnore: boolean) {
    try {
      if (this.getDockFlightTaskStatus() === 2) {
        await this.pauseFlightTask()
      } else if (this.getDockFlightTaskStatus() === 3) {
        await this.recoveryFlightTask()
      } else {
        throw new NotifyException('当前飞机未处于航线执行阶段，开启或者暂停航线失败', 'info')
      }
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  protected checkAircraftOnline () {
    if (!this.osd?.getAircraftOnline()) {
      throw new NotifyException('飞行器未上线', 'error')
    }
  }

  async pauseFlightTask () {
    this.checkAircraftOnline()
    if (this.getDockFlightTaskStatus() === 2) {
      await pauseFlightTask({ gatewaySn: this.gatewaySn })
    } else {
      throw new NotifyException('当前飞机未处于航线执行阶段，暂停航线失败', 'info')
    }
  }

  async recoveryFlightTask () {
    this.checkAircraftOnline()
    if (this.getDockFlightTaskStatus() === 3) {
      await recoveryFlightTask({ gatewaySn: this.gatewaySn })
    } else {
      throw new NotifyException('当前飞机未处于航线暂停阶段，恢复航线失败', 'info')
    }
  }

  // 一键返航
  async triggerReturnHome (catchIgnore: boolean) {
    try {
      if (this.isDockWorkingMode()) {
        await returnHome({ gatewaySn: this.gatewaySn })
        JlinkUtils.media.startTtsSpeech({ text: '飞机开始返航' })
      } else {
        throw new NotifyException('请确认飞机处于飞行状态', 'warning')
      }
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  async triggerReturnHomeCancel (catchIgnore: boolean) {
    try {
      await returnHomeCancel({ gatewaySn: this.gatewaySn })
      JlinkUtils.media.startTtsSpeech({ text: '飞机已暂停返航' })
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  // 一键起飞
  async triggerTakeOff (
    elevation: number,
    lat:number|undefined,
    lng:number|undefined,
    rthAltitude: number,
    securityTakeoffHeight: number,
    maxSpeed: number,
  ) {
    if (!this.isDockTakeOffAble()) {
      throw new NotifyException('飞行器当前不符合一键起飞条件', 'error')
    }
    if (!this.osd?.getDockOsdValue()) {
      throw new NotifyException('机场osd数据不存在，确定机场是否正常工作', 'error')
    }
    const latitude = lat || this.getDockLat()
    const longitude = lng || this.getDockLng()
    if (latitude && longitude) {
      const executeHeight = elevation
      await takeOffToPoint({
        gatewaySn: this.gatewaySn,
        position: [longitude.toFixedCustom(8), latitude.toFixedCustom(8), executeHeight.toFixedCustom(1)].join(','),
        rthAltitude,
        securityTakeoffHeight,
        maxSpeed,
        coordinate: MAP_COORDINATE
      })
      JlinkUtils.media.startTtsSpeech({ text: '飞机开始执行一键起飞' })
    } else {
      throw new NotifyException('机场信息不完张', 'error')
    }
  }

  async triggerFlyTo (
    id:string,
    elevation: number|undefined,
    lat:number,
    lng:number, maxSpeed: number) {
    // const dockHeight = this.getDockHeight()
    const targetHeight = elevation || this.getAircraftElevation()?.toFixedCustom(0)
    if (targetHeight !== undefined) {
      this.checkAircraftOnline()
      const position = lng + ',' + lat + ',' + targetHeight
      await flyToPoint({
        flyToId: id,
        gatewaySn: this.gatewaySn,
        position,
        maxSpeed,
        coordinate: MAP_COORDINATE
      })
    } else {
      throw new NotifyException('飞行器信息不完整', 'error')
    }
  }

  async triggerFlyToUpdate (
    elevation: number|undefined,
    lat:number,
    lng:number, maxSpeed: number) {
    // const dockHeight = this.getDockHeight()
    const targetHeight = elevation || this.getAircraftElevation()?.toFixedCustom(0)
    if (targetHeight !== undefined) {
      this.checkAircraftOnline()
      const position = lng + ',' + lat + ',' + targetHeight
      await updateToPoint({
        gatewaySn: this.gatewaySn,
        position,
        maxSpeed,
        coordinate: MAP_COORDINATE
      })
    } else {
      throw new NotifyException('飞行器信息不完整', 'error')
    }
  }

  async triggerFlyToPointStop (catchIgnore: boolean) {
    try {
      this.checkAircraftOnline()
      await flyToPointStop({ gatewaySn: this.gatewaySn })
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  dispatchJoystick (data: DroneJoystick) {
    this.osd?.drcServiceMqtt?.dispatchJoystick(data).then()
  }

  dispatchEmergencyStop () {
    this.osd?.drcServiceMqtt?.dispatchEmergencyStop()
  }
}
