import NotifyException from '@/common/errors/NotifyException'
import JlinkTask from '@/common/global/JlinkTask'
import { ref } from 'vue'
import { startLiveStream } from '@/api/api.dji.cloud.live'
import LivestreamBase from '@/common/mqtt/jlink/LivestreamBase'
import JlinkMqtt2 from '@/common/global/JlinkMqtt2'

export default class LivestreamGateway extends LivestreamBase {
  cameraPlayingOpen = ref(false)

  private checkGatewayOnline () {
    if (!JlinkMqtt2.getGatewayOnline(this.drone)) {
      throw new NotifyException('网关未上线', 'error')
    }
  }

  constructor (drone:DroneBaseVo) {
    super(drone, true)
  }

  setOnline (drone?: MqttDroneOnlineResult) {
    super.setOnline(drone)
  }

  setPushStartOrEnd (data?: PushStartOrEndResult) {
    if (data?.action === 'on_publish') {
      const splitValues = data.videoId.split('/')
      if (splitValues.length === 3) {
        const deviceValue = splitValues[0]
        const cameraValue = splitValues[1]
        const videoValue = splitValues[2]
        this.values.currentDevice = deviceValue
        this.values.currentCameraIndex = cameraValue
        this.values.currentVideoIndex = videoValue
        this.__setCurrentCamera(this.values.currentCameraIndex)
      }
      this.values.pushUrl = data.pushUrl
      this.values.pullUrl = data.pullUrl
      this.values.pullRtmpUrl = data.pullRtmpUrl
      this.values.pullFlvUrl = data.pullFlvUrl
      this.values.videoId = data.videoId
      this.values.sn = data?.sn
    } else if (data?.action === 'on_unpublish') {
      this.values.videoId = undefined
    } else {
      this.values.videoId = undefined
      console.error('直播推送数据类型错误')
    }
  }

  handleLiveStreamOpen () {
    const _this = this
    this.checkGatewayOnline()
    if (this.isPlaying()) {
      this.cameraPlayingOpen.value = !this.cameraPlayingOpen.value
    } else {
      JlinkTask.catchAwait(async function () {
        await startLiveStream({ videoId: _this.getCurrentSelectVideoId() })
        _this.cameraPlayingOpen.value = true
      })
    }
  }

  // 打开直播
  async handleLiveStreamToggle (catchIgnore: boolean) {
    try {
      this.checkGatewayOnline()
      await this.toggleLiveStream()
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  // 切换直播
  async switchLiveStreamOpen (camera: string | undefined, catchIgnore: boolean) {
    try {
      if (camera) {
        this.__setCurrentCamera(camera)
      }
      this.checkGatewayOnline()
      await this.switchOpenLiveStream()
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  async setQuality (currentClarity: number, catchIgnore: boolean) {
    try {
      this.checkGatewayOnline()
      await this.setLiveStreamQuality(currentClarity)
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }
}
