import { reactive, ref } from 'vue'
import { setLiveLens } from '@/api/api.dji.cloud.live'
import NotifyException from '@/common/errors/NotifyException'
import {
  cameraAim,
  cameraFocalLengthSet,
  cameraModeSwitch,
  cameraPhotoTake,
  cameraStartRecording,
  cameraStopRecording,
  gimbalReset
} from '@/api/api.drc'
import JlinkTask from '@/common/global/JlinkTask'
import JlinkUtils from '@/common/global/JlinkUtils'
import JlinkThrottle from '@/common/annotate/JlinkThrottle'
import JlinkMqtt from '@/common/global/JlinkMqtt'
import { thermalCurrentPaletteStyle } from '@/api/api.property'
import LivestreamBase from '@/common/mqtt/jlink/LivestreamBase'
import JlinkMqtt2 from '@/common/global/JlinkMqtt2'

export type CameraLenType = 'zoom' | 'ir' | 'wide' | 'normal' | ''

export default class LiveStreamAircraft extends LivestreamBase {
  private cameraIdle = JlinkTask.idleMaker(2000)
  readonly cameraValues: {
    recording: boolean
    photoing: boolean
    irZoomFactor: number
    zoomFactor: number
    recordTime: number
    remainRecordDuration: number
    remainPhotoNum: number
    cameraMode?: 0 | 1
    cameraTakePhotoShoot: boolean
    recordTimeCounterTime: number

  } = reactive({
      recording: false,
      photoing: false,
      irZoomFactor: 0,
      zoomFactor: 0,
      recordTime: 0,
      remainRecordDuration: 0,
      remainPhotoNum: 0,
      cameraMode: undefined,
      cameraTakePhotoShoot: false,
      recordTimeCounterTime: 0
    })

  constructor (drone: DroneBaseVo) {
    super(drone, false)
  }

  private recordingTrigger (type:number) {
    const _this = this
    if (this.isCameraRecording()) {
      if (!this.haveIntervalKey('recordingTimeCounter')) {
        this.addInterval('recordingTimeCounter', function () {
          _this.cameraValues.recordTimeCounterTime = _this.cameraValues.recordTimeCounterTime + 1
        }, 1000)
      }
    } else {
      if (this.haveIntervalKey('recordingTimeCounter')) {
        this.deleteInterval('recordingTimeCounter')
        if (this.cameraValues.recordTimeCounterTime !== 0) {
          this.cameraValues.recordTimeCounterTime = 0
        }
      }
    }
  }

  isCameraRecording () {
    return this.cameraValues.recording
  }

  isCameraPhotoing () {
    return this.cameraValues.photoing
  }

  isCameraPhotoingShoot () {
    return this.cameraValues.cameraTakePhotoShoot
  }

  getCameraIrZoomFactor () {
    return this.cameraValues.irZoomFactor
  }

  getCameraZoomFactor () {
    return this.cameraValues.zoomFactor
  }

  getCameraRecordTime () {
    return this.cameraValues.recordTime
  }

  getCameraMode () {
    return this.cameraValues.cameraMode
  }

  isCameraTakePhotoMode () {
    return this.getCameraMode() === 0
  }

  isCameraRecordingMode () {
    return this.getCameraMode() === 1
  }

  setOnline (drone?: MqttDroneOnlineResult) {
    super.setOnline(drone)
    if (!drone?.aircraftOnline) {
      this.cameraValues.recording = false
      this.recordingTrigger(0)
      this.cameraValues.photoing = false
      this.cameraValues.irZoomFactor = 0
      this.cameraValues.zoomFactor = 0
      this.cameraValues.cameraMode = undefined
      this.cameraValues.remainPhotoNum = 0
      this.cameraValues.remainRecordDuration = 0
      this.cameraValues.recordTime = 0
      this.cameraValues.cameraTakePhotoShoot = false
    }
  }

  setAircraftOsd (data: MqttAircraftOsdResult) {
    if (this.cameraIdle.getIdle()) {
      const currentCameraLiveStreamInfo = data.cameras?.findBy('payload_index', this.values.currentCameraIndex)
      if (currentCameraLiveStreamInfo) {
        this.cameraValues.recording = currentCameraLiveStreamInfo.recording_state === 1
        this.recordingTrigger(1)
        this.cameraValues.photoing = currentCameraLiveStreamInfo.photo_state === 1
        if (!this.cameraValues.photoing) {
          this.cameraValues.cameraTakePhotoShoot = false
        }
        this.cameraValues.irZoomFactor = currentCameraLiveStreamInfo.ir_zoom_factor || 0
        this.cameraValues.zoomFactor = currentCameraLiveStreamInfo.zoom_factor || 0
        this.cameraValues.cameraMode = currentCameraLiveStreamInfo.camera_mode
        this.cameraValues.remainPhotoNum = currentCameraLiveStreamInfo.remain_photo_num || 0
        this.cameraValues.remainRecordDuration = currentCameraLiveStreamInfo.remain_record_duration || 0
        this.cameraValues.recordTime = currentCameraLiveStreamInfo.record_time || 0
      } else {
        this.cameraValues.recording = false
        this.recordingTrigger(2)
        this.cameraValues.photoing = false
        this.cameraValues.irZoomFactor = 0
        this.cameraValues.zoomFactor = 0
        this.cameraValues.cameraMode = undefined
        this.cameraValues.remainPhotoNum = 0
        this.cameraValues.remainRecordDuration = 0
        this.cameraValues.recordTime = 0
        this.cameraValues.cameraTakePhotoShoot = false
      }
    }
  }

  setPushStartOrEnd (data?: PushStartOrEndResult) {
    if (data?.action === 'on_publish') {
      if (data.videoId) {
        const splitValues = data.videoId.split('/')
        if (splitValues.length === 3) {
          const deviceValue = splitValues[0]
          const cameraValue = splitValues[1]
          const videoValue = splitValues[2]
          this.values.currentDevice = deviceValue
          this.values.currentCameraIndex = cameraValue
          this.values.currentVideoIndex = videoValue
          this.__setCurrentCamera(this.values.currentCameraIndex)
          this.values.pushUrl = data.pushUrl
          this.values.pullUrl = data.pullUrl
          this.values.pullRtmpUrl = data.pullRtmpUrl
          this.values.pullFlvUrl = data.pullFlvUrl
          this.values.videoId = data.videoId
          // this.values.platform = data.platform
          this.values.sn = data.sn
          // this.values.gatewaySn = data.gatewaySn
        } else {
          console.error('videoId 格式错误，直播失败')
          this.values.videoId = undefined
        }
      } else {
        console.error('videoId 不存在，直播失败')
        this.values.videoId = undefined
      }
    } else if (data?.action === 'on_unpublish') {
      this.values.videoId = undefined
    } else {
      console.error('直播推送数据类型错误')
    }
  }

  thermalSupportedPaletteStyles = ref<number[]>([])

  setThermalSupportStyles (styles?: ThermalSupportStyles) {
    this.thermalSupportedPaletteStyles.value = styles?.thermal_supported_palette_styles || []
  }

  destroy () {
    super.destroy()
  }

  private getCamera () {
    if (!this.drone.gatewaySerialNumber) {
      throw new NotifyException('设备为准备就绪或者数据错误', 'error')
    }
    const camera = this.values.currentCameraIndex
    if (!camera) {
      throw new NotifyException('未找到操作负载', 'error')
    }
    return camera
  }

  private checkAircraftOnline () {
    if (!JlinkMqtt2.getAircraftOnline(this.drone)) {
      throw new NotifyException('飞行器未上线', 'error')
    }
  }

  // 切换直播通道
  async videoLensSelect (v: CameraLenType, catchIgnore: boolean) {
    try {
      this.checkAircraftOnline()
      if (this.values.videoId) {
        if (v === '') {
          throw new NotifyException('直播通道错误', 'warning')
        }
        await setLiveLens({ videoId: this.values.videoId, lens: v })
      } else {
        throw new NotifyException('直播未开启', 'warning')
      }
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  @JlinkThrottle(300, true, true)
  async cameraFocalLengthAsyncSet2 (zoomFactor: number, catchIgnore: boolean) {
    try {
      const camera = this.getCamera()
      this.checkAircraftOnline()
      this.cameraIdle.trigger()
      if (this.values.currentLen === 'ir') {
        await cameraFocalLengthSet({
          gatewaySn: this.drone.gatewaySerialNumber,
          zoomFactor,
          cameraType: 'ir',
          payloadIndex: camera
        })
        this.cameraValues.zoomFactor = zoomFactor
      }
      if (this.values.currentLen === 'zoom') {
        await cameraFocalLengthSet({
          gatewaySn: this.drone.gatewaySerialNumber,
          zoomFactor,
          cameraType: 'zoom',
          payloadIndex: camera
        })
        this.cameraValues.irZoomFactor = zoomFactor
      }
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  async cameraFocalLengthAsyncSet (action: 'zoomIncrease' | 'zoomDecrease', offsetFactor: number, catchIgnore: boolean) {
    if (this.values.currentLen === 'zoom') {
      const hybridZoomRate = this.getCurrentCameraCapacity()?.cameraParam.zoom?.hybridZoomRate
      if (hybridZoomRate?.length === 2 && hybridZoomRate[0] !== hybridZoomRate[1]) {
        const min = hybridZoomRate[0]
        const max = hybridZoomRate[1]
        if (action === 'zoomIncrease') {
          if (this.cameraValues.zoomFactor < max) {
            this.cameraValues.zoomFactor = (this.cameraValues.zoomFactor + offsetFactor).range(max, min)
            this.cameraFocalLengthAsyncSet2(this.cameraValues.zoomFactor, true).then()
          }
        } else {
          if (this.cameraValues.zoomFactor > min) {
            this.cameraValues.zoomFactor = (this.cameraValues.zoomFactor - offsetFactor).range(max, min)
            this.cameraFocalLengthAsyncSet2(this.cameraValues.zoomFactor, true).then()
          }
        }
      } else {
        console.error('当前变焦相机不支持混合变焦')
      }
    }
    if (this.values.currentLen === 'ir') {
      const hybridZoomRate = this.getCurrentCameraCapacity()?.cameraParam.ir?.hybridZoomRate
      if (hybridZoomRate?.length === 2 && hybridZoomRate[0] !== hybridZoomRate[1]) {
        const min = hybridZoomRate[0]
        const max = hybridZoomRate[1]
        if (action === 'zoomIncrease') {
          if (this.cameraValues.irZoomFactor < max) {
            this.cameraValues.irZoomFactor = (this.cameraValues.irZoomFactor + offsetFactor).range(max, min)
            this.cameraFocalLengthAsyncSet2(this.cameraValues.irZoomFactor, true).then()
          }
        } else {
          if (this.cameraValues.irZoomFactor > min) {
            this.cameraValues.irZoomFactor = (this.cameraValues.irZoomFactor - offsetFactor).range(max, min)
            this.cameraFocalLengthAsyncSet2(this.cameraValues.irZoomFactor, true).then()
          }
        }
      } else {
        console.error('当前热成相相机不支持混合变焦')
      }
    }
  }

  async cameraFocalLengthReset () {
    if (this.values.currentLen === 'zoom') {
      const hybridZoomRate = this.getCurrentCameraCapacity()?.cameraParam.zoom?.hybridZoomRate
      if (hybridZoomRate?.length === 2 && hybridZoomRate[0] !== hybridZoomRate[1]) {
        const min = hybridZoomRate[0]
        this.cameraValues.zoomFactor = min
        this.cameraFocalLengthAsyncSet2(this.cameraValues.zoomFactor, true).then()
      } else {
        console.error('当前变焦相机不支持混合变焦')
      }
    }
    if (this.values.currentLen === 'ir') {
      const hybridZoomRate = this.getCurrentCameraCapacity()?.cameraParam.ir?.hybridZoomRate
      if (hybridZoomRate?.length === 2 && hybridZoomRate[0] !== hybridZoomRate[1]) {
        const min = hybridZoomRate[0]
        this.cameraValues.irZoomFactor = min
        this.cameraFocalLengthAsyncSet2(this.cameraValues.irZoomFactor, true).then()
      } else {
        console.error('当前热成相相机不支持混合变焦')
      }
    }
  }

  async cameraSwitchMode (catchIgnore: boolean) {
    try {
      if (this.values.currentCameraIndex) {
        this.checkAircraftOnline()
        this.cameraIdle.trigger()
        if (this.cameraValues.cameraMode === 0) {
          this.cameraValues.cameraMode = 1
          await cameraModeSwitch({
            gatewaySn: this.drone.gatewaySerialNumber,
            cameraMode: 1,
            payloadIndex: this.values.currentCameraIndex
          })
        } else {
          this.cameraValues.cameraMode = 0
          await cameraModeSwitch({
            gatewaySn: this.drone.gatewaySerialNumber,
            cameraMode: 0,
            payloadIndex: this.values.currentCameraIndex
          })
        }
      }
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  async switchLenCircle (catchIgnore: boolean) {
    try {
      this.checkAircraftOnline()
      const index = Math.max(this.values.currentLen ? this.values.currentLens.indexOf(this.values.currentLen) : 0, 0)
      const current = this.values.currentLens[index + 1] || this.values.currentLens[0]
      if (current) {
        if (this.values.videoId) {
          await this.videoLensSelect(current as CameraLenType, catchIgnore)
        }
      } else {
        console.error('直播能力不存在')
      }
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  async cameraAimSet (target: string, catchIgnore: boolean) {
    try {
      const camera = this.getCamera()
      this.checkAircraftOnline()
      const split = target.split(',')
      const x = Number(split[0])
      const y = Number(split[1])
      const param = {
        tid: JlinkUtils.random.uuid(28),
        bid: JlinkUtils.random.uuid(28),
        method: 'camera_aim',
        timestamp: Date.now(),
        data: {
          payload_index: camera,
          locked: false,
          camera_type: this.values.currentLen,
          x,
          y
        }
      }
      await JlinkMqtt.send(`thing/product/${this.drone.gatewaySerialNumber}/services`, JSON.stringify(param), {})

      // await cameraAim({
      //   gatewaySn: this.drone.gatewaySerialNumber,
      //   // 'x,y' x,y=>[0,1]
      //   target: target,
      //   locked: false,
      //   cameraType: this.values.currentLen as 'zoom' | 'ir' | 'wide',
      //   payloadIndex: this.currentCamera.value
      // })
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  async cameraPhotoTake (catchIgnore: boolean) {
    try {
      const camera = this.getCamera()
      this.checkAircraftOnline()
      if (this.cameraValues.cameraMode !== 0) {
        this.cameraValues.cameraMode = 0
        await cameraModeSwitch({
          gatewaySn: this.drone.gatewaySerialNumber,
          cameraMode: this.cameraValues.cameraMode,
          payloadIndex: camera
        })
      }
      this.cameraValues.cameraTakePhotoShoot = true
      await cameraPhotoTake({ gatewaySn: this.drone.gatewaySerialNumber, payloadIndex: camera })
      await JlinkUtils.media.startTtsSpeech({ text: '拍照完成' })
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    } finally {
      this.cameraValues.cameraTakePhotoShoot = false
    }
  }

  async cameraRecordStart (catchIgnore: boolean) {
    try {
      const camera = this.getCamera()
      this.checkAircraftOnline()
      if (this.cameraValues.cameraMode !== 1) {
        this.cameraIdle.trigger()
        this.cameraValues.cameraMode = 1
        await cameraModeSwitch({
          gatewaySn: this.drone.gatewaySerialNumber,
          cameraMode: this.cameraValues.cameraMode,
          payloadIndex: camera
        })
      }
      if (!this.cameraValues.recording) {
        this.cameraIdle.trigger()
        this.cameraValues.recording = true
        await cameraStartRecording({
          gatewaySn: this.drone.gatewaySerialNumber,
          payloadIndex: camera
        })
        JlinkUtils.media.startTtsSpeech({ text: '开始录像' })
      } else {
        console.warn('当前处于录像状态开启录像失败')
      }
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  async cameraRecordStop (catchIgnore: boolean) {
    try {
      const camera = this.getCamera()
      this.checkAircraftOnline()
      if (this.cameraValues.cameraMode !== 1) {
        this.cameraIdle.trigger()
        this.cameraValues.cameraMode = 1
        await cameraModeSwitch({
          gatewaySn: this.drone.gatewaySerialNumber,
          cameraMode: this.cameraValues.cameraMode,
          payloadIndex: camera
        })
      }
      if (this.cameraValues.recording) {
        this.cameraIdle.trigger()
        this.cameraValues.recording = false
        await cameraStopRecording({ gatewaySn: this.drone.gatewaySerialNumber, payloadIndex: camera })
        JlinkUtils.media.startTtsSpeech({ text: '录像完成' })
      } else {
        console.warn('当前处于录像停止状态停止录像失败')
      }
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  async cameraRecordToggle (catchIgnore: boolean) {
    try {
      this.checkAircraftOnline()
      this.getCamera()
      if (this.cameraValues.recording) {
        await this.cameraRecordStop(catchIgnore)
      } else {
        await this.cameraRecordStart(catchIgnore)
      }
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  async cameraGimbalDirectionReset (gimbalResetMode: 0 | 1 | 2 | 3, catchIgnore: boolean) {
    try {
      this.checkAircraftOnline()
      const camera = this.getCamera()
      await gimbalReset({
        gatewaySn: this.drone.gatewaySerialNumber,
        gimbalResetMode,
        payloadIndex: camera
      })
      switch (gimbalResetMode) {
        case 0:
          JlinkUtils.media.startTtsSpeech({ text: '云台回中' })
          break
        case 2:
          JlinkUtils.media.startTtsSpeech({ text: '云台偏航角回中' })
          break
        case 3:
          JlinkUtils.media.startTtsSpeech({ text: '云台俯仰角向下' })
          break
      }
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  async cameraAutoOpenLensSwitchableCamera (catchIgnore: boolean) {
    try {
      this.checkAircraftOnline()
      this.getCamera()
      if (this.isPlaying()) {
        const target = this.values.currentLens
        // 如果只有普通的播放通道(一般是飞行器自带相机)
        // 则切换相机
        if (target.length < 1) {
          const target = this.__findLensSwitchableCamera()[0]
          if (target) {
            await this.switchLiveStreamOpen(target.camera_index, true)
          }
        }
      } else {
        const target = this.__findLensSwitchableCamera()[0]
        if (target) {
          await this.switchLiveStreamOpen(target.camera_index, true)
        }
      }
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  // 打开直播
  async handleLiveStreamToggle (catchIgnore: boolean) {
    try {
      this.checkAircraftOnline()
      await this.toggleLiveStream()
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  // 切换直播
  async switchLiveStreamOpen (camera: string | undefined, catchIgnore: boolean) {
    try {
      this.checkAircraftOnline()
      if (camera) {
        this.__setCurrentCamera(camera)
      }
      await this.switchOpenLiveStream()
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }

  async setThermalPaletteStyle (styleId: number) {
    const currentCamera = this.getCurrentCameraIndex()
    if (currentCamera) {
      await thermalCurrentPaletteStyle({
        gatewaySn: this.drone.gatewaySerialNumber,
        payloadIndex: currentCamera,
        style: styleId
      })
    } else {
      throw new NotifyException('未找到相机数据', 'error')
    }
  }

  async setQuality (currentClarity: number, catchIgnore: boolean) {
    try {
      this.checkAircraftOnline()
      await this.setLiveStreamQuality(currentClarity)
    } catch (e) {
      if (!catchIgnore) {
        throw e
      }
    }
  }
}
