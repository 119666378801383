import BaseHelper from '@/common/helper/BaseHelper'

export default class JlinkWorkerBase<T extends JlinkWorkerRequestAndResponse> extends BaseHelper {
  protected readonly worker: Worker
  private callback: ((event:T['RESPONSE'])=>void)|undefined
  working = true
  constructor (worker: Worker,) {
    super()
    this.worker = worker
    this.working = true
    this.worker.addEventListener('messageerror', this.workErrorCallback)
    this.worker.addEventListener('terminate', this.workErrorCallback)
  }

  protected setCallback (callback: (event: MessageEvent<T['RESPONSE']>)=>void) {
    this.callback && this.worker.removeEventListener('message', this.callback)
    this.callback = callback
    this.worker.addEventListener('message', this.callback)
  }

  private workErrorCallback (e:Event) {
    this.working = false
  }

  protected post (args: T['POST']) {
    this.worker.postMessage(args)
  }

  destroy () {
    super.destroy()
    this.callback && this.worker.removeEventListener('message', this.callback)
    this.worker.terminate()
  }
}
