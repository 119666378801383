import { FlightControlModule } from '@/store/FlightControl'
import DrcServiceBase, { KeyCode } from '@/common/mqtt/DrcServiceBase'
import { DroneJoystick } from '@/common/mqtt/jlink/DrcServiceMqtt'

export default class KeyboardDrcService extends DrcServiceBase {
  private joyStickSeq = 0
  private zoomSeq = 0
  private aimSeq = 0
  constructor () {
    super()
    const _this = this
    this.addInterval('KeyboardDrcServiceFps', function () {
      if (_this.keySave.size > 0) {
        _this.zoomFunc()
        _this.cameraAimFunc()
        _this.droneControlFunc()
      } else {
        _this.zoomSeq = 0
        _this.aimSeq = 0
        _this.joyStickSeq = 0
      }
    }, 100)
  }

  async create (activeCallback: (a: boolean) => void) {
    await super.create(activeCallback)
    this.activeCallback && this.activeCallback(true)
    window.addEventListener('keyup', this.onKeyup)
    window.addEventListener('keydown', this.onKeydown)
    window.addEventListener('mouseover', this.onMouseOver)
    window.addEventListener('mouseout', this.onMouseOut)
  }

  async destroy () {
    console.log('handleInfo destroy', this.id)
    window.removeEventListener('keyup', this.onKeyup)
    window.removeEventListener('keydown', this.onKeydown)
    window.removeEventListener('mouseover', this.onMouseOver)
    window.removeEventListener('mouseout', this.onMouseOut)
    super.destroy()
  }

  private onKeyup = this.onActionUp.bind(this)
  private onKeydown = this.onActionDown.bind(this)
  private onMouseOver = this.onActionOver.bind(this)
  private onMouseOut = this.onActionOut.bind(this)
  private onActionOver (e: MouseEvent) {
    // @ts-ignore
    const fromElement = e.fromElement
    // @ts-ignore
    const toElement = e.toElement
    if (!fromElement) {
      this.keySave.clear()
      this.zoomSeq = 0
      this.aimSeq = 0
      this.joyStickSeq = 0
    }
  }

  private onActionOut (e: MouseEvent) {
    // @ts-ignore
    const fromElement = e.fromElement
    // @ts-ignore
    const toElement = e.toElement
    if (!toElement) {
      this.keySave.clear()
      this.zoomSeq = 0
      this.aimSeq = 0
      this.joyStickSeq = 0
    }
  }

  private zoomFunc () {
    if (this.keySave.has(KeyCode.ZOOM_INCREASE) && this.keySave.has(KeyCode.ZOOM_DECREASE)) {
      this.zoomSeq = 0
    } else {
      if (this.keySave.has(KeyCode.ZOOM_INCREASE)) {
        const offset = (this.zoomSeq.range(5, 1))
        this.zoomIncrease(offset)
        this.zoomSeq++
      }
      if (this.keySave.has(KeyCode.ZOOM_DECREASE)) {
        const offset = (this.zoomSeq.range(5, 1))
        this.zoomDecrease(offset)
        this.zoomSeq++
      }
    }
  }

  private cameraAimFunc () {
    const params: number[] = [0.5, 0.5]
    const offset = (this.aimSeq.range(5, 1))
    if (this.keySave.has(KeyCode.GIMBAL_DOWN)) {
      params[1] += 0.1 * offset
    }
    if (this.keySave.has(KeyCode.GIMBAL_UP)) {
      params[1] -= 0.1 * offset
    }
    if (this.keySave.has(KeyCode.GIMBAL_LEFT)) {
      params[0] -= 0.1 * offset
    }
    if (this.keySave.has(KeyCode.GIMBAL_RIGHT)) {
      params[0] += 0.1 * offset
    }
    if (params[0] === 0.5 && params[1] === 0.5) {
      this.aimSeq = 0
    } else {
      this.cameraAim(params)
      this.aimSeq++
    }
  }

  private droneControlFunc () {
    const params: DroneJoystick = {}
    // 上
    if (this.keySave.has(KeyCode.KEY_W)) {
      const h = (params.h || 0) + FlightControlModule.tunesSetting.VirtualRockerAdjustment.verticalSpeed
      params.h = h & h
    }
    // 下
    if (this.keySave.has(KeyCode.KEY_S)) {
      const h = (params.h || 0) - FlightControlModule.tunesSetting.VirtualRockerAdjustment.verticalSpeed
      params.h = h & h
    }

    // 左转
    if (this.keySave.has(KeyCode.KEY_A)) {
      const w = (params.w || 0) - FlightControlModule.tunesSetting.VirtualRockerAdjustment.palstance
      params.w = w & w
    }
    // 右转
    if (this.keySave.has(KeyCode.KEY_D)) {
      const w = (params.w || 0) + FlightControlModule.tunesSetting.VirtualRockerAdjustment.palstance
      params.w = w & w
    }

    // 前
    if (this.keySave.has(KeyCode.ARROW_UP)) {
      const x = (params.x || 0) + FlightControlModule.tunesSetting.VirtualRockerAdjustment.horizontalSpeed
      params.x = x & x
    }
    // 后
    if (this.keySave.has(KeyCode.ARROW_DOWN)) {
      const x = (params.x || 0) - FlightControlModule.tunesSetting.VirtualRockerAdjustment.horizontalSpeed
      params.x = x & x
    }

    // 左
    if (this.keySave.has(KeyCode.ARROW_LEFT)) {
      const y = (params.y || 0) - FlightControlModule.tunesSetting.VirtualRockerAdjustment.horizontalSpeed
      params.y = y & y
    }
    // 右
    if (this.keySave.has(KeyCode.ARROW_RIGHT)) {
      const y = (params.y || 0) + FlightControlModule.tunesSetting.VirtualRockerAdjustment.horizontalSpeed
      params.y = y & y
    }
    if (Object.keys(params).length > 0) {
      this.dispatchDroneControl({ seq: this.joyStickSeq, ...params })
      this.joyStickSeq++
    } else {
      this.joyStickSeq = 0
    }
  }

  onActionUp (event: KeyboardEvent) {
    this.keySave.delete(event.code)
  }

  private keySave = new Set<string>()

  onActionDown (event: KeyboardEvent) {
    if (!this.keySave.has(event.code)) {
      this.keySave.add(event.code)
      // 急停
      if (this.keySave.has(KeyCode.KEY_SPACE)) {
        this.keySave.delete(KeyCode.KEY_W)
        this.keySave.delete(KeyCode.KEY_S)
        this.keySave.delete(KeyCode.KEY_A)
        this.keySave.delete(KeyCode.KEY_D)
        this.keySave.delete(KeyCode.ARROW_UP)
        this.keySave.delete(KeyCode.ARROW_DOWN)
        this.keySave.delete(KeyCode.ARROW_LEFT)
        this.keySave.delete(KeyCode.ARROW_RIGHT)
        this.handleEmergencyStop()
        return
      }
      // 云台回中
      if (this.keySave.has(KeyCode.GIMBAL_CENTER)) {
        this.gimbalReset(0)
        return
      }
      // 拍照
      if (this.keySave.has(KeyCode.TAKE_PHOTO)) {
        this.takePhoto()
        return
      }
      // 录像
      if (this.keySave.has(KeyCode.RECORD)) {
        this.toggleRecord()
        return
      }
      // 切换地图或者直播页面
      // if (this.keySave.has(KeyCode.SWITCH_PANEL)) {
      //   this.toggleMapSwitch()
      //   return
      // }
      // // 修正
      // if (this.keySave.has(KeyCode.FIX)) {
      //   this.tempStr = ''
      //   return
      // }
      // 切换直播通道
      if (this.keySave.has(KeyCode.SWITCH_PLAY_LEN)) {
        this.switchLiverLen()
        return
      }
      if (this.keySave.has(KeyCode.TOGGLE_WAYLINE_TASK)) {
        this.toggleWaylineTaskPauseOrResume()
        return
      }

      // // 虚拟摇杆水平速度++
      // if (this.keySave.has(KeyCode.HS_INCREASE)) {
      //   FlightControlModule.tunesSetting.VirtualRockerAdjustment.horizontalSpeed++
      //   return
      // }
      // // 虚拟摇杆水平速度--
      // if (this.keySave.has(KeyCode.HS_DECREASE)) {
      //   FlightControlModule.tunesSetting.VirtualRockerAdjustment.horizontalSpeed--
      //   return
      // }
      //
      // // 虚拟摇杆上升速度++
      // if (this.keySave.has(KeyCode.VS_INCREASE)) {
      //   FlightControlModule.tunesSetting.VirtualRockerAdjustment.verticalSpeed++
      //   return
      // }
      // // 虚拟摇杆下降速度--
      // if (this.keySave.has(KeyCode.VS_DECREASE)) {
      //   FlightControlModule.tunesSetting.VirtualRockerAdjustment.verticalSpeed--
      //   return
      // }
      //
      // // 虚拟摇杆角速度++
      // if (this.keySave.has(KeyCode.W_INCREASE)) {
      //   FlightControlModule.tunesSetting.VirtualRockerAdjustment.palstance++
      //   return
      // }
      // // 虚拟摇杆角速度--
      // if (this.keySave.has(KeyCode.W_DECREASE)) {
      //   FlightControlModule.tunesSetting.VirtualRockerAdjustment.palstance--
      //   return
      // }
      // 一键返航--
      if (this.keySave.has(KeyCode.RETURN_HOME)) {
        this.returnBack()
      }
    }
  }
}
