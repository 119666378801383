import { Ref, reactive, ref } from 'vue'
import { mittError } from '@/common/mitt/mitt'
import NotifyException from '@/common/errors/NotifyException'
import { setLiveQuality, startLiveStream, stopLiveStream } from '@/api/api.dji.cloud.live'
import JlinkTask from '@/common/global/JlinkTask'
import { DjiDeviceEnum } from '@/common/dji/DjiDeviceEnum'
import BaseHelper from '@/common/helper/BaseHelper'

export default abstract class LivestreamBase extends BaseHelper {
  readonly gateway: boolean
  readonly cameraClarityList = [
    { value: 0, label: '自动' },
    { value: 1, label: '流畅' },
    { value: 2, label: '标清' },
    { value: 3, label: '高清' },
    { value: 4, label: '超清' }
  ]

  protected checkParams (throwOut: boolean = true): boolean {
    if (!this.values.currentDevice) {
      if (throwOut) {
        mittError(new NotifyException('该设备不具有播放能力', 'warning'))
      }
      return false
    }
    if (!this.values.currentCameraIndex) {
      if (throwOut) {
        mittError(new NotifyException('请选择飞机播放设备', 'warning'))
      }
      return false
    }
    if (this.values.currentVideoIndex === null || this.values.currentVideoIndex === undefined) {
      this.values.currentVideoIndex = ''
      // if (throwOut) {
      //   mittError(new NotifyException('请选择飞机播放通道', 'warning'))
      // }
      // return false
    }
    return true
  }

  drone: DroneBaseVo

  protected values = reactive<{
    cameraLiveCapacity?: LiveCapacity
    currentDevice?: string
    currentCameraIndex?: string
    currentVideoIndex?: string
    currentCameraList: CameraInfo[]
    currentVideoList: VideoInfo[]
    cameraLiveStatus: LiveStatus[]
    currentLiveStatus?: LiveStatus
    currentLens:('wide' | 'zoom' | 'ir' | 'normal')[]
    currentLen?: 'wide' | 'zoom' | 'ir' | 'normal'
      } & FormatRecord<PushStartOrEndResult>>({
        cameraLiveStatus: [],
        currentCameraList: [],
        currentVideoList: [],
        currentLens: []
      })

  setLiveCapacity (msg?: LiveCapacity) {
    console.log('liveCapacity', msg)
    this.values.cameraLiveCapacity = msg
    const device = msg?.device_list?.findBy('sn', this.getCurrentDeviceSn())
    if (device) {
      this.values.currentDevice = device.sn
      this.__setCapacity(device.camera_list)
    } else {
      this.values.currentDevice = undefined
      this.__setCapacity([])
    }
  }

  abstract setPushStartOrEnd (data?: PushStartOrEndResult):void

  private __setCapacity (cap: CameraInfo[]) {
    this.values.currentCameraList = cap
    if (!this.__getCameraInclude(this.values.currentCameraIndex)) {
      this.__setCurrentCamera(this.values.currentCameraList[0]?.camera_index)
    } else {
      this.__setCurrentCamera(this.values.currentCameraIndex)
    }
  }

  protected __setCurrentCamera (cameraIndex?: string) {
    if (cameraIndex && this.__getCameraInclude(cameraIndex)) {
      const camera = this.__getCamera(cameraIndex)
      this.values.currentCameraIndex = camera?.camera_index
      this.values.currentVideoList = camera?.video_list || []
      if (!this.__getVideoInclude(this.values.currentVideoIndex)) {
        this.__setCurrentVideo(camera?.video_list[0]?.video_index)
      } else {
        this.__setCurrentVideo(this.values.currentVideoIndex)
      }
    } else {
      this.values.currentCameraIndex = undefined
      this.values.currentVideoList = []
      this.__setCurrentVideo(undefined)
      console.warn(`cameraIndex ${cameraIndex} 未找到播放能力`)
    }
  }

  protected __setCurrentVideo (videoIndex?: string) {
    if (videoIndex && this.__getVideoInclude(videoIndex)) {
      const video = this.__getVideo(videoIndex)
      this.values.currentVideoIndex = video?.video_index
      const switchableLens = video?.switchable_video_types.filter(i => i !== 'normal') || []
      if (JSON.stringify(switchableLens) !== JSON.stringify(this.values.currentLens)) {
        this.values.currentLens = switchableLens
      }
      this.values.currentLen = video?.video_type
      this.values.currentLiveStatus = this.values.cameraLiveStatus.findBy('video_id', this.getCurrentSelectVideoId())
    } else {
      this.values.currentVideoIndex = undefined
      this.values.currentLens = []
      this.values.currentLen = undefined
      this.values.currentLiveStatus = undefined
      console.warn(`videoIndex ${videoIndex} 未找到播放能力`)
    }
  }

  protected __getCamera (cameraIndex?: string) {
    return this.values.currentCameraList.findBy('camera_index', cameraIndex)
  }

  protected __getCameraInclude (cameraIndex?: string) {
    return !!this.__getCamera(cameraIndex)
  }

  protected __getVideo (videoIndex?: string) {
    return this.values.currentVideoList.findBy('video_index', videoIndex)
  }

  protected __getVideoInclude (videoIndex?: string) {
    return !!this.__getVideo(videoIndex)
  }

  protected __findLensSwitchableCamera () {
    return this.values.currentCameraList.filter(i => i.video_list.any(i => i.switchable_video_types.length > 1))
  }

  private getCurrentDeviceSn () {
    return this.gateway ? this.drone.gatewaySerialNumber : this.drone.aircraftSerialNumber
  }

  getCurrentLensLabelValue () {
    return this.values.currentLens.map<LabelValuePair>(i => {
      switch (i) {
        case 'wide':
          return { value: i, label: '广角' }
        case 'zoom':
          return { value: i, label: '变焦' }
        case 'ir':
          return { value: i, label: '红外' }
        case 'normal':
          return { value: i, label: '普通' }
        default:
          return { value: i, label: i }
      }
    }) || []
  }

  getCurrentCameraListLabelValue () {
    return this.values.currentCameraList.map<LabelValuePair>(i => {
      return { label: i.camera_index_desc || i.camera_index || '未知负载', value: i.camera_index || '-1' }
    }) || []
  }

  getCurrentVideoListLabelValue () {
    return this.values.currentVideoList.map<LabelValuePair>(i => {
      return { label: i.video_index || '未知通道', value: i.video_index || '-1' }
    }) || []
  }

  getCurrentLen () {
    return this.values.currentLen
  }

  getCurrentLens () {
    return this.values.currentLens
  }

  getCurrentCameraIndex () {
    return this.values.currentCameraIndex
  }

  getCurrentLiveStatus () {
    return this.values.currentLiveStatus
  }

  getCurrentPullUrl () {
    return this.values.pullUrl
  }

  getCurrentPullRtmpUrl () {
    return this.values.pullRtmpUrl
  }

  getCurrentCameraCapacity () {
    if (this.values.currentCameraIndex) {
      const s = this.values.currentCameraIndex.split('-')
      s.unshift('1')
      s.length = 3
      return DjiDeviceEnum.getPayloadByEnumKey(s.join('-'))
    }
  }

  getCurrentCameraZoomRange () {
    const c = this.getCurrentCameraCapacity()?.cameraParam.zoom
    return c?.hybridZoomRate || [0, 0]
  }

  getCurrentCameraIrRange () {
    const c = this.getCurrentCameraCapacity()?.cameraParam.ir
    return c?.hybridZoomRate || [0, 0]
  }

  getCurrentPlayVideoId () {
    return this.values.videoId
  }

  getCurrentSelectVideoId () {
    return this.values.currentDevice + '/' + this.values.currentCameraIndex + '/' + this.values.currentVideoIndex || ''
  }

  isPlaying () {
    return !!this.values.videoId
  }

  setLiveStatus (msg?: LiveStatus[]) {
    this.values.cameraLiveStatus = msg || []
    this.values.currentLiveStatus = this.values.cameraLiveStatus.findBy('video_id', this.getCurrentSelectVideoId())
  }

  // private pushStartOrEndData:PushStartOrEndResult[]|undefined

  setOnline (data?: MqttDroneOnlineResult) {
    if (!data?.gatewayOnline) {
      this.values = reactive({
        cameraLiveStatus: [],
        currentCameraList: [],
        currentVideoList: [],
        currentLens: []
      })
    }
  }

  protected constructor (drone: DroneBaseVo, gateway: boolean) {
    super()
    this.gateway = gateway
    this.drone = drone
  }

  // 打开直播
  protected async toggleLiveStream () {
    if (this.values.videoId) {
      try {
        await stopLiveStream({ videoId: this.values.videoId })
      } catch (e) {
        console.error(e)
      }
    } else {
      if (this.checkParams(true)) {
        await JlinkTask.asyncGet(() => !this.values.videoId, 5, 1000)
        await startLiveStream({ videoId: this.getCurrentSelectVideoId() })
      }
    }
  }

  // 切换直播
  protected async switchOpenLiveStream () {
    if (this.values.videoId === this.getCurrentSelectVideoId()) {
      return
    }
    if (this.values.videoId) {
      try {
        await stopLiveStream({ videoId: this.values.videoId })
      } catch (e) {
        console.log(e)
      }
    }
    if (this.checkParams(true)) {
      await JlinkTask.asyncGet(() => !this.values.videoId, 5, 1000)
      await startLiveStream({ videoId: this.getCurrentSelectVideoId() })
    }
  }

  protected async setLiveStreamQuality (currentClarity: number) {
    const clarity = this.cameraClarityList.findBy('value', currentClarity)
    if (!clarity) {
      throw new NotifyException('currentClarity is not support in clarityList', 'error')
    }
    if (!this.values.videoId) {
      throw new NotifyException('playingVideoId is lost', 'error')
    }
    await setLiveQuality({ videoId: this.values.videoId, videoQuality: currentClarity })
  }
}
