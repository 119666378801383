import JlinkTransform from '@/common/global/JlinkTransform'

import JlinkMqttReactiveBase from '@/common/mqtt/jlink/JlinkMqttReactiveBase'
import { AIRCRAFT_MODE_CODE } from '@/common/enum/DEVICE_ENUMS'

export default class OsdAircraftValues {
  protected osd: JlinkMqttReactiveBase | undefined
  protected gatewaySn:string
  protected aircraftSn:string
  constructor (osd: JlinkMqttReactiveBase) {
    this.osd = osd
    this.gatewaySn = this.osd.drone.gatewaySerialNumber
    this.aircraftSn = this.osd.drone.aircraftSerialNumber
  }

  updateDrone (drone:DroneBaseVo) {
    this.gatewaySn = drone.gatewaySerialNumber
    this.aircraftSn = drone.aircraftSerialNumber
  }

  getAircraftHmsInfo () {
    return this.osd?.getAircraftHmsValue()?.map(JlinkTransform.dockOsd.translateHms)
  }

  /* -------------组合数据-------------- */
  getAircraftLowPowerWarning () {
    const osd = this.osd?.getAircraftOsdValue()
    return osd && [
      AIRCRAFT_MODE_CODE.M_FLY,
      AIRCRAFT_MODE_CODE.A_FLY,
      AIRCRAFT_MODE_CODE.W_FLY,
      AIRCRAFT_MODE_CODE.PHOTO_SPHERE,
      AIRCRAFT_MODE_CODE.FOLLOW,
      AIRCRAFT_MODE_CODE.ADS_B,
      AIRCRAFT_MODE_CODE.JOYSTICK,
      AIRCRAFT_MODE_CODE.COMMAND_FLY]
      .includes(osd.modeCode) && osd.batteryCapacityPercent < 30
  }

  getAircraftMode () {
    return this.osd?.getAircraftOsdValue()?.modeCode
  }

  getAircraftModeZh () {
    return JlinkTransform.aircraftOsd.toAircraftModeCodeDesc(this.getAircraftMode())
  }

  getAircraftBatteryPercent () {
    return this.osd?.getAircraftOsdValue()?.batteryCapacityPercent || this.osd?.getDockOsdValue()?.aircraftCapacityPercent || 0
  }

  getAircraftElevation () {
    return this.osd?.getAircraftOsdValue()?.elevation
  }

  getAircraftHorizontalSpeed () {
    return this.osd?.getAircraftOsdValue()?.horizontalSpeed
  }

  getAircraftVerticalSpeed () {
    return this.osd?.getAircraftOsdValue()?.verticalSpeed
  }

  getAircraftAttitudeHead () {
    return this.osd?.getAircraftOsdValue()?.attitudeHead
  }

  getAircraftPosition () {
    const target = this.osd?.getAircraftOsdValue()
    return target && { lng: target.lng, lat: target.lat, height: target.height }
  }

  getAircraftLng () {
    return this.osd?.getAircraftOsdValue()?.lng
  }

  getAircraftLat () {
    return this.osd?.getAircraftOsdValue()?.lat
  }

  getAircraftHeight () {
    return this.osd?.getAircraftOsdValue()?.height
  }

  getAircraftHeightLimit () {
    return this.osd?.getAircraftOsdValue()?.heightLimit
  }

  getAircraftWindSpeed () {
    return this.osd?.getAircraftOsdValue()?.windSpeed
  }

  getAircraftWindSpeedUnit (f: number = 2, unit: string = 'm/s') {
    const target = this.osd?.getAircraftOsdValue()
    return target?.windSpeed && (target.windSpeed / 10).toFixedCustom(f) + unit
  }

  getAircraftBatteryRemainFlightTime () {
    return this.osd?.getAircraftOsdValue()?.batteryRemainFlightTime
  }

  getAircraftTotalFlightDistance () {
    return this.osd?.getAircraftOsdValue()?.totalFlightDistance
  }

  getAircraftObstacleAvoidanceDownside () {
    return this.osd?.getAircraftOsdValue()?.obstacleAvoidanceDownside
  }

  getAircraftActivationTime () {
    return this.osd?.getAircraftOsdValue()?.activationTime
  }

  getAircraftTotalFlightTime () {
    return this.osd?.getAircraftOsdValue()?.totalFlightTime
  }

  getAircraftFirmwareVersion () {
    return this.osd?.getAircraftOsdValue()?.firmwareVersion
  }

  getAircraftTotalFlightSorties () {
    return this.osd?.getAircraftOsdValue()?.totalFlightSorties
  }

  getAircraftHomeDistance () {
    return this.osd?.getAircraftOsdValue()?.homeDistance
  }

  getAircraftObstacleAvoidanceDownsideZh () {
    return JlinkTransform.aircraftOsd.toObstacleAvoidanceDownside(this.getAircraftObstacleAvoidanceDownside())
  }

  getAircraftObstacleAvoidanceHorizon () {
    return this.osd?.getAircraftOsdValue()?.obstacleAvoidanceHorizon
  }

  getAircraftObstacleAvoidanceHorizonZh () {
    return JlinkTransform.aircraftOsd.toObstacleAvoidanceHorizon(this.getAircraftObstacleAvoidanceHorizon())
  }

  getAircraftObstacleAvoidanceUpside () {
    return this.osd?.getAircraftOsdValue()?.obstacleAvoidanceUpside
  }

  getAircraftObstacleAvoidanceUpsideZh () {
    return JlinkTransform.aircraftOsd.toObstacleAvoidanceUpside(this.getAircraftObstacleAvoidanceUpside())
  }

  getAircraftPositionRtkNumber () {
    return this.osd?.getAircraftOsdValue()?.positionRtkNumber
  }

  getAircraftBatteryLandingPower () {
    return this.osd?.getAircraftOsdValue()?.batteryLandingPower
  }

  getAircraftBatteryReturnHomePower () {
    return this.osd?.getAircraftOsdValue()?.batteryReturnHomePower
  }

  getAircraftPositionGpsNumber () {
    return this.osd?.getAircraftOsdValue()?.positionGpsNumber
  }

  getAircraftPositionQuality () {
    return this.osd?.getAircraftOsdValue()?.positionQuality
  }

  getAircraftPositionQualityZh () {
    return JlinkTransform.aircraftOsd.toPositionQuality(this.getAircraftPositionQuality())
  }

  getAircraftPositionIsFixed () {
    return this.osd?.getAircraftOsdValue()?.positionIsFixed
  }

  getAircraftPositionIsFixedZh () {
    return JlinkTransform.aircraftOsd.toPositionIsFixed(this.getAircraftPositionIsFixed())
  }

  getAircraftDistanceState () {
    return this.osd?.getAircraftOsdValue()?.distanceState
  }

  getAircraftDistanceStateZh () {
    return JlinkTransform.aircraftOsd.toDistanceState(this.getAircraftDistanceState())
  }

  getAircraftSn () {
    return this.osd?.getAircraftOsdValue()?.aircraftSn
  }

  getAircraftPayloads () {
    return this.osd?.getAircraftOsdValue()?.payloads
  }

  getAircraftDistanceLimit () {
    return this.osd?.getAircraftOsdValue()?.distanceLimit
  }

  getAircraftStorageTotal () {
    const target = this.osd?.getAircraftOsdValue()
    return target?.storageTotal && (target.storageTotal * 1024)
  }

  getAircraftStorageTotalUnit (f: number = 1) {
    return this.getAircraftStorageTotal()?.asByteSizeFormat(f)
  }

  getAircraftStorageUsed () {
    const target = this.osd?.getAircraftOsdValue()
    return target?.storageUsed && (target?.storageUsed * 1024)
  }

  getAircraftStorageUsedUnit (f: number = 1) {
    return this.getAircraftStorageUsed()?.asByteSizeFormat(f)
  }

  // 飞行器是否允许返航
  // 飞行器状态     enum   {"0":"待机","1":"起飞准备","2":"起飞准备完毕","3":"手动飞行","4":"自动起飞","5":"航线飞行","6":"全景拍照","7":"智能跟随","8":"ADS-B躲避","9":"自动返航","10":"自动降落","11":"强制降落","12":"三桨叶降落","13":"升级中","14":"未连接",15":"辅助飞行功能 - APAS","16":"虚拟摇杆模式"}
  isAircraftReturnAble () {
    const target = this.osd?.getAircraftOsdValue()?.modeCode
    return target !== undefined && [
      AIRCRAFT_MODE_CODE.M_FLY,
      AIRCRAFT_MODE_CODE.A_FLY,
      AIRCRAFT_MODE_CODE.W_FLY,
      AIRCRAFT_MODE_CODE.PHOTO_SPHERE,
      AIRCRAFT_MODE_CODE.FOLLOW,
      AIRCRAFT_MODE_CODE.ADS_B,
      AIRCRAFT_MODE_CODE.APAS,
      AIRCRAFT_MODE_CODE.JOYSTICK,
      AIRCRAFT_MODE_CODE.COMMAND_FLY].includes(target)
  }

  isAircraftCameraUseAble () {
    const target = this.osd?.getAircraftOsdValue()?.modeCode

    return target !== undefined && [
      AIRCRAFT_MODE_CODE.M_FLY,
      AIRCRAFT_MODE_CODE.A_FLY,
      AIRCRAFT_MODE_CODE.W_FLY,
      AIRCRAFT_MODE_CODE.PHOTO_SPHERE,
      AIRCRAFT_MODE_CODE.FOLLOW,
      AIRCRAFT_MODE_CODE.ADS_B,
      AIRCRAFT_MODE_CODE.APAS,
      AIRCRAFT_MODE_CODE.JOYSTICK,
      AIRCRAFT_MODE_CODE.COMMAND_FLY
    ].includes(target)
  }

  getAircraftBatteryPercentIcon () {
    const p = this.getAircraftBatteryPercent()
    if (p >= 0 && p < 20) {
      return { icon: 'svg-battery-1', style: 'danger' }
    } else if (p >= 20 && p < 40) {
      return { icon: 'svg-battery-2', style: 'warn' }
    } else if (p >= 40 && p < 70) {
      return { icon: 'svg-battery-3', style: 'full' }
    } else if (p >= 70) {
      return { icon: 'svg-battery-4', style: 'full' }
    } else {
      return { icon: 'svg-battery-0', style: 'danger' }
    }
  }

  destory () {
    this.osd = undefined
  }
}
