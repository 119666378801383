// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import WorkerScript from 'worker-loader!./script/WorkerAxios.ts'
import JlinkWorkerBase from '@/common/workers/JlinkWorkerBase'
import { AxiosRequest } from '@/common/workers/JlinkWorkerAxiosValue'
import { AxiosProgressEvent, CreateAxiosDefaults } from 'axios'
import RequestException from '@/common/errors/RequestException'
import ResponseException from '@/common/errors/ResponseException'
import { toRaw } from 'vue'
import JlinkUtils from '@/common/global/JlinkUtils'

export default class JlinkWorkerAxios extends JlinkWorkerBase<AxiosRequest> {
  private promiseSave: StringKeyRecord<{
    time: number
    resolve: (value: any | PromiseLike<any>) => void,
    reject: (reason?: any) => void,
    onUploadProgress?: (e: AxiosProgressEvent) => void,
    onDownloadProgress?: (e: AxiosProgressEvent) => void
  }> = {}

  constructor (options: CreateAxiosDefaults) {
    super(WorkerScript())
    const _this = this
    this.setCallback(function (res) {
      switch (res.data.type) {
        case 'init':
          console.log(`JlinkWorkerAxios init ${res.data.message}`)
          break
        case 'request':
          const promise = _this.promiseSave[res.data.bid]
          if (promise) {
            const code = res.data.code
            const message = res.data.message
            const data = res.data.data
            if (code === 0) {
              promise.resolve(data)
            } else {
              promise.reject(new ResponseException(message, code))
            }
            delete _this.promiseSave[res.data.bid]
          }
          break
        case 'progress':
          const promise1 = _this.promiseSave[res.data.bid]
          if (promise1) {
            if (res.data.action === 'upload' && promise1.onUploadProgress) {
              promise1.onUploadProgress(res.data.progress)
            }
            if (res.data.action === 'download' && promise1.onDownloadProgress) {
              promise1.onDownloadProgress(res.data.progress)
            }
          }
          break
        case 'cancel':
          const promise2 = _this.promiseSave[res.data.bid]
          if (promise2) {
            promise2.reject(new RequestException('request is cancel'))
            delete _this.promiseSave[res.data.bid]
          }
          console.log(`JlinkWorkerAxios cancel ${res.data.bid} ${res.data.message}`)
          break
      }
    })
    this.post({ type: 'init', options })
    this.addInterval('sss', function () {
      if (!_this.working) {
        _this.destroy()
        return
      }
      const now = Date.now()
      Object.keys(_this.promiseSave).forEach(function (k) {
        const p = _this.promiseSave[k]
        if (now - p.time > (options.timeout || 30000) + 10000) {
          p.reject(new RequestException('timeout with axios worker do not receive any response'))
          delete _this.promiseSave[k]
        }
      })
    }, 10000)
  }

  private makeParam (param:any) {
    const pureObj = toRaw(param)
    if (typeof pureObj === 'object') {
      Object.keys(pureObj).forEach(item => {
        const data = pureObj[item]
        pureObj[item] = this.makeParam(data)
      })
    }

    return pureObj
  }

  request<T extends RequestAndResponse> (bid:string, url: T['url'], method: T['method'], params: T['Request'], headers:StringKeyRecord<any>, onUploadProgress?: (e: AxiosProgressEvent) => void, onDownloadProgress?: (e: AxiosProgressEvent) => void) {
    const _this = this
    const pure = this.makeParam(params)
    console.log(params, pure)
    this.post({ type: 'request', bid, url, method, data: pure, headers })
    return new Promise((resolve, reject) => { _this.promiseSave[bid] = { time: Date.now(), resolve, reject, onUploadProgress, onDownloadProgress } })
  }
}
