import OSS from 'tiny-oss'

import { ClientBase } from '@/common/oss/ClientBase'

export default class AliClient implements ClientBase {
  ossClient: OSS
  credential:OssCredentialVo
  constructor (result:OssCredentialVo) {
    this.credential = result
    this.ossClient = new OSS({
      secure: result.ssl,
      bucket: result.bucket,
      accessKeyId: result.credentials.access_key_id,
      accessKeySecret: result.credentials.access_key_secret,
      stsToken: result.credentials.security_token
    })
  }

  putObject (key: string, file: File, options?: OSS.PutOptions) {
    return this.ossClient.put(key, file, options)
  }

  async getSignUrl (key: string, options?: OSS.SignatureUrlOptions) {
    return this.ossClient.signatureUrl(key, options || {})
  }
}
