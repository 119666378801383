import { reactive, Ref, ref } from 'vue'
import JlinkMqtt from '@/common/global/JlinkMqtt'
import { DATA_COORDINATE, MAP_COORDINATE } from '@/common/global/JlinkValues'
import CoordinateUtil from '@/common/global/util/CoordinateUtil'
import MqttException from '@/common/errors/MqttException'
import JlinkUtils from '@/common/global/JlinkUtils'
import JlinkTransform from '@/common/global/JlinkTransform'
import JlinkType from '@/common/global/JlinkType'
import DrcServiceMqtt from '@/common/mqtt/jlink/DrcServiceMqtt'
import PsdkSpeaker from '@/common/mqtt/jlink/PsdkSpeaker'
import LivestreamGateway from '@/common/mqtt/jlink/LivestreamGateway'
import LiveStreamAircraft from '@/common/mqtt/jlink/LiveStreamAircraft'
import OsdGatewayValues from '@/common/mqtt/jlink/OsdGatewayValues'
import { JOB_TYPE } from '@/common/enum/JLINK_ENUMS'
import { parseTaskWaylineInfo } from '@/api/api.wayline'
import JlinkTask from '@/common/global/JlinkTask'

type OtaUpgradeResult={
  sn:string
  output:{
    status:'sent'|'in_progress'|'ok'|'paused'|'rejected'|'failed'|'canceled'|'timeout'
    progress:{
      percent: number
      step_key: 'download_firmware'|'upgrade_firmware'
      step_result: number
      total_steps:number
    }
  }
}

enum RegisterWhen {
  MODIFY,
  GATEWAY_ONLINE,
  RC_ONLINE,
  DOCK_ONLINE,
  AIRCRAFT_ONLINE
}
type RegisterParamModel<T, R> = {
  value: Ref<R | undefined>,
  register: RegisterWhen
  topic: T,
  patten: string
  callback: MqttMsgHandler<R>
}

interface RegisterParam {
  // 1设备上下线监听
  __onlineParam(): RegisterParamModel<'drone/+/online', MqttDroneOnlineResult>

  // 2飞行器数据监听
  __aircraftOsdParam(): RegisterParamModel<'drone/aircraft/+/osd', MqttAircraftOsdResult>

  // 3飞行器健康数据监听
  __aircraftHmsParam(): RegisterParamModel<'drone/+/hms', HmsResult[]>

  // 4机场数据监听
  __dockOsdParam(): RegisterParamModel<'drone/dock/+/osd', MqttDockOsdResult>

  // 5机场任务进度监听
  __dockFlightTaskProcessParam(): RegisterParamModel<'job/task/+/process', FlightTaskProcessResult>

  // 6机场flyTo进度监听
  __dockFlyToProcessParam(): RegisterParamModel<'drone/+/drc/flyToPoint', FlyToProcess>

  // 7网关健康数据监听
  __dockHmsParam(): RegisterParamModel<'drone/+/hms', HmsResult[]>

  // 8遥控数据监听
  __rcOsdParam(): RegisterParamModel<'drone/rc/+/osd', MqttRcOsdResult>

  // 9机场文件上传优先级监听
  __dockUploadHighestPriorityParam(): RegisterParamModel<'job/task/+/highestPriority', { taskId: string }>

  // 10机场固件升级进度监听
  __dockOtaUpgradeParam(): RegisterParamModel<'drone/dock/+/otaUpgrade', OtaUpgradeResult>

  // 11机场文件上传进度信息监听
  __dockMediaCountInfoUpdatedParam(): RegisterParamModel<'job/task/+/mediaCountInfoUpdated', DockFlightTaskMediaCountUpdateResult>

  // 12机场文件上传进度信息监听
  __dockDroneFileUploadedParam(): RegisterParamModel<'drone/+/fileUpload', UploadFileWrapperVo>

  // 13机场远程控制进度信息监听
  __dockRemoteParam(): RegisterParamModel<'drone/dock/+/remote', DockRemoteResult>

  // 14设备播放能力信息监听
  __droneLiveCapacityParam(): RegisterParamModel<'drone/+/liveCapacity', LiveCapacity>

  // 15网关直播推送信息监听
  __gatewayPushStartOrEndParam(): RegisterParamModel<'drone/gateway/+/live/pushStartOrEnd', PushStartOrEndResult>

  // 16飞行器直播推送信息监听
  __aircraftPushStartOrEndParam(): RegisterParamModel<'drone/gateway/+/live/pushStartOrEnd', PushStartOrEndResult>

  // 17飞行器直播推送信息监听
  __droneLiveStatusParam(): RegisterParamModel<'drone/+/liveStatus', LiveStatus[]>

  // 18飞行器直播推送信息监听
  __aircraftThermalSupportStylesParam(): RegisterParamModel<'drone/+/thermal/styles', ThermalSupportStyles>

  // 19飞行器直播推送信息监听
  __dockPsdkWidgetValuesParam(): RegisterParamModel<'drone/+/psdk/widget', PsdkWidgetValuesReceive>

  // 19飞行器直播推送信息监听
  __dockPsdkSpeakerPlayStartProgressParam(): RegisterParamModel<'drone/+/psdk/speakerPlayStartProgress', SpeakerPlayStartProgress>

  // 19飞行器直播推送信息监听
  __dockPsdkUiResourceUploadReceiveParam(): RegisterParamModel<'drone/+/psdk/uiResourceUpload', PsdkUiResourceUploadReceive>

  __dockControlSourceParam(): RegisterParamModel< 'drone/+/controlSource', ControlSource>

}

enum DroneType {
  RC,
  DOCK,
  TEMP
}

export default class JlinkMqttReactiveBase implements RegisterParam {
  // 该类活跃标记
  active = 0
  // 飞机类型
  private readonly droneType: DroneType
  // 坐标辅助类
  protected coordinate = new CoordinateUtil()
  // 当前飞机
  readonly drone: DroneBaseVo
  // 该类唯一标记
  private uuid = JlinkUtils.random.uuid(8)
  // 虚拟摇杆飞行通道
  drcServiceMqtt: DrcServiceMqtt | undefined
  // 高频数据更新时间记录
  private highFrequencySaveTime = 0
  // 飞行器播放器
  private aircraftLiveStream: LiveStreamAircraft | undefined
  // 网关播放器
  private gatewayLiveStream: LivestreamGateway | undefined
  // 机场喊话器
  private dockSpeaker: PsdkSpeaker | undefined
  // 机场数据功能
  private dockValues: OsdGatewayValues | undefined

  private waylineSave = ref<StringKeyRecord<TemplateWaylineInfo|undefined>>({})

  constructor (drone: DroneBaseVo) {
    this.drone = drone
    if (JlinkType.drone.isDock(this.drone)) {
      this.droneType = DroneType.DOCK
      const _this = this
      // 如果是机场开启虚拟摇杆飞行通道
      this.drcServiceMqtt = new DrcServiceMqtt(drone.gatewaySerialNumber, function (msg) {
        // 高频数据更新
        if (msg.method === 'osd_info_push') {
          const dockOsd = _this.getDockOsdValue()
          if (!dockOsd) {
            return
          }
          const oldOsd = _this.getAircraftOsdValue()

          const aircraftOsd = oldOsd && { ...oldOsd }
          const data = msg.data
          if (aircraftOsd && data.longitude && data.latitude) {
            const coordinate = JlinkUtils.coordinate.coordinateTransform({
              lng: data.longitude,
              lat: data.latitude,
              height: data.height
            }, DATA_COORDINATE, MAP_COORDINATE)
            aircraftOsd.highFrequency = true
            aircraftOsd.lat = coordinate.lat
            aircraftOsd.lng = coordinate.lng
            aircraftOsd.height = coordinate.height
            aircraftOsd.verticalSpeed = -data.speed_z
            aircraftOsd.horizontalSpeed = Math.sqrt(Math.pow(data.speed_x, 2) + Math.pow(data.speed_y, 2))
            aircraftOsd.attitudeHead = data.attitude_head
            aircraftOsd.elevation = coordinate.height - dockOsd.height
            const p = aircraftOsd.payloads?.first()
            if (p) {
              p.gimbal_pitch = data.gimbal_pitch
              p.gimbal_roll = data.gimbal_roll
              p.gimbal_yaw = data.gimbal_yaw
            }

            _this.getConfig('__aircraftOsdParam').value.value = aircraftOsd
            _this.highFrequencySaveTime = Date.now()
          }
        }
      })
      this.register('__onlineParam')
    } else if (JlinkType.drone.isRc(this.drone)) {
      this.register('__onlineParam')
      this.droneType = DroneType.RC
    } else {
      const config = this.getConfig('__onlineParam')
      config.callback({ ...this.drone, gatewayOnline: true, aircraftOnline: true }, config.topic)
      this.droneType = DroneType.TEMP
    }
  }

  __dockControlSourceParam () {
    const topic: 'drone/+/controlSource' = 'drone/+/controlSource'
    const patten = this.drone.gatewaySerialNumber
    const register = RegisterWhen.GATEWAY_ONLINE
    const value = ref<ControlSource>()
    const callback = function (msg: ControlSource, topic: string) {
      value.value = msg
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __dockPsdkUiResourceUploadReceiveParam () {
    const topic: 'drone/+/psdk/uiResourceUpload' = 'drone/+/psdk/uiResourceUpload'
    const patten = this.drone.gatewaySerialNumber
    const register = RegisterWhen.AIRCRAFT_ONLINE
    const _this = this
    const value = ref<PsdkUiResourceUploadReceive>()
    const callback = function (msg: PsdkUiResourceUploadReceive, topic: string) {
      _this.dockSpeaker?.setPsdkUiResourceUploadReceive(msg)
      value.value = msg
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __dockPsdkSpeakerPlayStartProgressParam () {
    const topic: 'drone/+/psdk/speakerPlayStartProgress' = 'drone/+/psdk/speakerPlayStartProgress'
    const patten = this.drone.gatewaySerialNumber
    const register = RegisterWhen.AIRCRAFT_ONLINE
    const _this = this
    const value = ref<SpeakerPlayStartProgress>()
    const callback = function (msg: SpeakerPlayStartProgress, topic: string) {
      _this.dockSpeaker?.setPsdkSpeakerPlayStartProgress(msg)
      value.value = msg
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __dockPsdkWidgetValuesParam () {
    const topic: 'drone/+/psdk/widget' = 'drone/+/psdk/widget'
    const patten = this.drone.gatewaySerialNumber
    const register = RegisterWhen.AIRCRAFT_ONLINE
    const _this = this
    const value = ref<PsdkWidgetValuesReceive>()
    const callback = function (msg: PsdkWidgetValuesReceive, topic: string) {
      _this.dockSpeaker?.setPsdkWidgetValues(msg)
      value.value = msg
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __aircraftThermalSupportStylesParam () {
    const topic: 'drone/+/thermal/styles' = 'drone/+/thermal/styles'
    const patten = this.drone.gatewaySerialNumber
    const register = RegisterWhen.AIRCRAFT_ONLINE
    const _this = this
    const value = ref<ThermalSupportStyles>()
    const callback = function (msg: ThermalSupportStyles, topic: string) {
      _this.aircraftLiveStream?.setThermalSupportStyles(msg)
      value.value = msg
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __droneLiveStatusParam () {
    const topic: 'drone/+/liveStatus' = 'drone/+/liveStatus'
    const patten = this.drone.gatewaySerialNumber
    const register = RegisterWhen.GATEWAY_ONLINE
    const _this = this
    const value = ref<LiveStatus[]>()
    const callback = function (msg: LiveStatus[], topic: string) {
      _this.gatewayLiveStream?.setLiveStatus(msg)
      _this.aircraftLiveStream?.setLiveStatus(msg)
      value.value = msg
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __gatewayPushStartOrEndParam () {
    const topic: 'drone/gateway/+/live/pushStartOrEnd' = 'drone/gateway/+/live/pushStartOrEnd'
    const patten = this.drone.gatewaySerialNumber
    const register = RegisterWhen.GATEWAY_ONLINE
    const _this = this
    const value = ref<PushStartOrEndResult>()
    const callback = function (msg: PushStartOrEndResult, topic: string) {
      _this.gatewayLiveStream?.setPushStartOrEnd(msg)
      value.value = msg
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __aircraftPushStartOrEndParam () {
    const topic: 'drone/gateway/+/live/pushStartOrEnd' = 'drone/gateway/+/live/pushStartOrEnd'
    const patten = this.drone.aircraftSerialNumber
    const register = RegisterWhen.AIRCRAFT_ONLINE
    const _this = this
    const value = ref<PushStartOrEndResult>()
    const callback = function (msg: PushStartOrEndResult, topic: string) {
      _this.aircraftLiveStream?.setPushStartOrEnd(msg)
      value.value = msg
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __droneLiveCapacityParam () {
    const topic: 'drone/+/liveCapacity' = 'drone/+/liveCapacity'
    const patten = this.drone.gatewaySerialNumber
    const register = RegisterWhen.GATEWAY_ONLINE
    const _this = this
    const value = ref<LiveCapacity>()
    const callback = function (msg: LiveCapacity, topic: string) {
      _this.aircraftLiveStream?.setLiveCapacity(msg)
      _this.gatewayLiveStream?.setLiveCapacity(msg)
      value.value = msg
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __dockDroneFileUploadedParam () {
    const topic: 'drone/+/fileUpload' = 'drone/+/fileUpload'
    const patten = this.drone.droneId
    const register = RegisterWhen.GATEWAY_ONLINE
    const value = ref<UploadFileWrapperVo>()
    const callback = function (msg: UploadFileVo, topic: string) {
      value.value = JlinkTransform.makeUploadFileWrapper(msg)
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __dockMediaCountInfoUpdatedParam () {
    const topic: 'job/task/+/mediaCountInfoUpdated' = 'job/task/+/mediaCountInfoUpdated'
    const register = RegisterWhen.DOCK_ONLINE
    const patten = this.drone.gatewaySerialNumber
    const value = ref<DockFlightTaskMediaCountUpdateResult>()
    const callback = function (msg: DockFlightTaskMediaCountUpdateResult, topic: string) {
      value.value = msg
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __rcOsdParam () {
    const _this = this
    const topic: 'drone/rc/+/osd' = 'drone/rc/+/osd'
    const patten = this.drone.gatewaySerialNumber
    const register = RegisterWhen.RC_ONLINE
    const value = ref<MqttRcOsdResult>()
    const callback = function (msg: MqttRcOsdResult, topic: string) {
      if (msg.lng && msg.lat) {
        const coordinate = _this.coordinate.coordinateTransform({
          lng: msg.lng,
          lat: msg.lat,
          height: 0
        }, DATA_COORDINATE, MAP_COORDINATE)
        msg.lng = coordinate.lng
        msg.lat = coordinate.lat
      }
      value.value = msg
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __dockOtaUpgradeParam () {
    const topic: 'drone/dock/+/otaUpgrade' = 'drone/dock/+/otaUpgrade'
    const patten = this.drone.gatewaySerialNumber
    const register = RegisterWhen.DOCK_ONLINE
    const value = ref<OtaUpgradeResult>()
    const callback = function (msg: OtaUpgradeResult, topic: string) {
      value.value = msg
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __dockUploadHighestPriorityParam () {
    const topic: 'job/task/+/highestPriority' = 'job/task/+/highestPriority'
    const patten = this.drone.gatewaySerialNumber
    const register = RegisterWhen.DOCK_ONLINE
    const value = ref<{ taskId: string }>()
    const callback = function (msg: { taskId: string }, topic: string) {
      value.value = msg
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __dockRemoteParam () {
    const topic: 'drone/dock/+/remote' = 'drone/dock/+/remote'
    const patten = this.drone.gatewaySerialNumber
    const register = RegisterWhen.DOCK_ONLINE
    const value = ref<DockRemoteResult>()
    const callback = function (msg: DockRemoteResult, topic: string) {
      console.log(msg)
      value.value = msg
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __dockOsdParam () {
    const _this = this
    const topic: 'drone/dock/+/osd' = 'drone/dock/+/osd'
    const patten = this.drone.gatewaySerialNumber
    const register = RegisterWhen.DOCK_ONLINE
    const value = ref<MqttDockOsdResult>()
    const callback = function (msg: MqttDockOsdResult, topic: string) {
      if (msg.lng && msg.lat) {
        const coordinate = _this.coordinate.coordinateTransform({
          lng: msg.lng,
          lat: msg.lat,
          height: msg.height
        }, DATA_COORDINATE, MAP_COORDINATE)
        msg.lng = coordinate.lng
        msg.lat = coordinate.lat
      }
      if (msg.alternateLandPointLongitude && msg.alternateLandPointLatitude) {
        const coordinate = _this.coordinate.coordinateTransform({
          lng: msg.alternateLandPointLongitude,
          lat: msg.alternateLandPointLatitude,
          height: msg.alternateLandPointSafeLandHeight
        }, DATA_COORDINATE, MAP_COORDINATE)
        msg.alternateLandPointLongitude = coordinate.lng
        msg.alternateLandPointLatitude = coordinate.lat
      }
      _this.drcServiceMqtt?.updateDrcCode(msg.drcState)
      value.value = msg
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __aircraftHmsParam () {
    const topic: 'drone/+/hms' = 'drone/+/hms'
    const patten = this.drone.gatewaySerialNumber
    const register = RegisterWhen.AIRCRAFT_ONLINE
    const value = ref<HmsResult[]>()
    const callback = function (msg: HmsResult[], topic: string) {
      value.value = msg
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __dockHmsParam () {
    const topic: 'drone/+/hms' = 'drone/+/hms'
    const patten = this.drone.aircraftSerialNumber
    const register = RegisterWhen.DOCK_ONLINE
    const value = ref<HmsResult[]>()
    const callback = function (msg: HmsResult[], topic: string) {
      value.value = msg
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __aircraftOsdParam () {
    const _this = this
    const topic: 'drone/aircraft/+/osd' = 'drone/aircraft/+/osd'
    const patten = this.drone.aircraftSerialNumber
    const register = RegisterWhen.AIRCRAFT_ONLINE
    const value = ref<MqttAircraftOsdResult>()
    const callback = function (msg: MqttAircraftOsdResult, topic: string) {
      // 如果超过2秒没有接收到高频数据更新则使用osd数据更新
      if (Date.now() - _this.highFrequencySaveTime > 2000) {
        if (msg.lng && msg.lat) {
          const coordinate = _this.coordinate.coordinateTransform({
            lng: msg.lng,
            lat: msg.lat,
            height: msg.height
          }, DATA_COORDINATE, MAP_COORDINATE)
          msg.lng = coordinate.lng
          msg.lat = coordinate.lat
        }
        msg.payloads?.forEach(function (it) {
          const coordinate = _this.coordinate.coordinateTransform({
            lng: it.measure_target_longitude,
            lat: it.measure_target_latitude,
            height: 0
          }, DATA_COORDINATE, MAP_COORDINATE)
          it.measure_target_longitude = coordinate.lng
          it.measure_target_latitude = coordinate.lat
        })
        msg.highFrequency = false
        value.value = msg
      }
      _this.aircraftLiveStream?.setAircraftOsd(msg)
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __dockFlyToProcessParam () {
    const topic: 'drone/+/drc/flyToPoint' = 'drone/+/drc/flyToPoint'
    const patten = this.drone.gatewaySerialNumber
    const register = RegisterWhen.AIRCRAFT_ONLINE
    const value = ref<FlyToProcess>()
    // const triggerClear = JlinkTask.loopDelay(function () {
    //   value.value = undefined
    // }, 3000)

    const callback = function (msg: FlyToProcess, topic: string) {
      value.value = msg
      // triggerClear.triggerDelay(undefined)
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  __dockFlightTaskProcessParam () {
    const topic: 'job/task/+/process' = 'job/task/+/process'
    const patten = this.drone.gatewaySerialNumber
    const register = RegisterWhen.DOCK_ONLINE
    const value = ref<FlightTaskProcessResult>()
    const _this = this
    // const triggerClear = JlinkTask.loopDelay(function () {
    //   value.value = undefined
    // }, 3000)

    const callback = function (msg: FlightTaskProcessResult, topic: string) {
      value.value = msg
      // triggerClear.triggerDelay(undefined)
      if (msg.jobType === JOB_TYPE.TAKEOFF) {
        return undefined
      } else {
        const key = msg.taskId
        if (!_this.waylineSave.value.hasOwnProperty(key)) {
          _this.waylineSave.value[key] = undefined
          parseTaskWaylineInfo({ taskId: key, coordinate: MAP_COORDINATE })
            .then((res) => {
              _this.waylineSave.value[key] = res
            }).catch(() => {
              delete _this.waylineSave.value[key]
            })
        }
      }
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  protected getConfig<T extends keyof RegisterParam> (type: T): ReturnType<RegisterParam[T]> {
    const configName = `${type}__config`
    // @ts-ignore
    if (this[configName]) {
      // @ts-ignore
      return this[configName]
    } else {
      // @ts-ignore
      if (this[type]) {
        // @ts-ignore
        this[configName] = this[type]()
        // @ts-ignore
        return this[configName]
      } else {
        throw new MqttException(`${type} 属性未配置`)
      }
    }
  }

  __onlineParam () {
    const _this = this
    const topic: 'drone/+/online' = 'drone/+/online'
    const patten = this.drone.droneId
    const register = RegisterWhen.MODIFY
    const value = ref<MqttDroneOnlineResult>()
    const callback = function (msg: MqttDroneOnlineResult, topic: string) {
      value.value = msg
      JlinkUtils.obj.dataSet(msg, _this.drone)
      _this.dockValues?.updateDrone(_this.drone)
      _this.aircraftLiveStream?.setOnline(msg)
      _this.gatewayLiveStream?.setOnline(msg)
      _this.dockSpeaker?.setOnline(msg)
      Object.keys(_this.__getReactiveValueFlag).forEach(item => {
        _this.autoRegister(item as keyof RegisterParam, msg)
      })
      if (!msg.aircraftOnline) {
        // 飞行器下线的时候关闭虚拟控制器如果虚拟控制器仍在工作中
        _this.drcServiceMqtt?.updateDrcCode(0)
      }
    }
    callback.uuid = this.uuid
    return { topic, patten, register, value, callback }
  }

  private autoRegister<T extends keyof RegisterParam> (type: T, online?: MqttDroneOnlineResult) {
    const config = this.getConfig(type)
    if (config.register === RegisterWhen.MODIFY) {
      throw new MqttException(`${type} 不支持自动注册或者取消注册`)
    }
    if (!online) {
      this.unregister(type)
      return
    }
    if (config.register === RegisterWhen.GATEWAY_ONLINE) {
      if (online.gatewayOnline) {
        this.register(type)
      } else {
        this.unregister(type)
      }
    } else if (config.register === RegisterWhen.RC_ONLINE && this.droneType === DroneType.RC) {
      if (online.gatewayOnline) {
        this.register(type)
      } else {
        this.unregister(type)
      }
    } else if (config.register === RegisterWhen.DOCK_ONLINE && this.droneType === DroneType.DOCK) {
      if (online.gatewayOnline) {
        this.register(type)
      } else {
        this.unregister(type)
      }
    } else if (config.register === RegisterWhen.AIRCRAFT_ONLINE) {
      if (online.aircraftOnline) {
        this.register(type)
      } else {
        this.unregister(type)
      }
    }
  }

  private register<T extends keyof RegisterParam> (type: T) {
    const config = this.getConfig(type)
    if (config.register === RegisterWhen.MODIFY) {
      JlinkMqtt.mqtt.register(config.topic.replace('+', config.patten), 1, config.callback).then()
    } else {
      if (this.__getReactiveValueFlag[type] === undefined) {
        this.__getReactiveValueFlag[type] = false
      }
      if (!this.__getReactiveValueFlag[type]) {
        JlinkMqtt.mqtt.register(config.topic.replace('+', config.patten), 1, config.callback).then()
        this.__getReactiveValueFlag[type] = true
      }
    }
  }

  private unregister<T extends keyof RegisterParam> (type: T) {
    const config = this.getConfig(type)
    if (config.register === RegisterWhen.MODIFY) {
      JlinkMqtt.mqtt.unregister(config.topic.replace('+', config.patten), config.callback).then()
      config.value.value = undefined
    } else {
      if (this.__getReactiveValueFlag[type] === undefined) {
        this.__getReactiveValueFlag[type] = false
      }
      if (this.__getReactiveValueFlag[type]) {
        JlinkMqtt.mqtt.unregister(config.topic.replace('+', config.patten), config.callback).then()
        config.value.value = undefined
        this.__getReactiveValueFlag[type] = false
      }
    }
  }

  destory () {
    const config = this.getConfig('__onlineParam')
    config.callback({ ...this.drone, gatewayOnline: false, aircraftOnline: false }, config.topic)
    if (this.droneType === DroneType.DOCK || this.droneType === DroneType.RC) {
      this.unregister('__onlineParam')
    }
    this.__getReactiveValueFlag = {}
    this.drcServiceMqtt?.destroy()
    this.drcServiceMqtt = undefined
    this.aircraftLiveStream?.destroy()
    this.aircraftLiveStream = undefined
    this.gatewayLiveStream?.destroy()
    this.gatewayLiveStream = undefined
    this.dockSpeaker?.destory()
    this.dockSpeaker = undefined
    this.dockValues?.destory()
    this.dockValues = undefined
    this.waylineSave.value = {}
  }

  private __getReactiveValueFlag: StringKeyRecord<boolean> = {}

  protected getReactiveValue<T extends keyof RegisterParam> (type: T): ReturnType<RegisterParam[T]>['value']['value'] {
    // console.log("getReactiveValue",type)
    const config = this.getConfig(type)
    if (config.register !== RegisterWhen.MODIFY && this.__getReactiveValueFlag[type] === undefined) {
      this.autoRegister(type, this.getOnlineValue())
    }
    return config.value.value
  }

  getFlightWaylineSave () {
    return this.waylineSave.value
  }

  getAircraftOsdValue () {
    return this.getReactiveValue('__aircraftOsdParam')
  }

  getDockOsdValue () {
    return this.getReactiveValue('__dockOsdParam')
  }

  getRcOsdValue () {
    return this.getReactiveValue('__rcOsdParam')
  }

  getDockFlightTaskProcessValue () {
    return this.getReactiveValue('__dockFlightTaskProcessParam')
  }

  getDockFlyToProcessValue () {
    return this.getReactiveValue('__dockFlyToProcessParam')
  }

  getDockHmsValue () {
    return this.getReactiveValue('__dockHmsParam')
  }

  getAircraftHmsValue () {
    return this.getReactiveValue('__aircraftHmsParam')
  }

  getDockRemoteValue () {
    return this.getReactiveValue('__dockRemoteParam')
  }

  getDockUploadHighestPriorityValue () {
    return this.getReactiveValue('__dockUploadHighestPriorityParam')
  }

  getDockOtaUpgradeValue () {
    return this.getReactiveValue('__dockOtaUpgradeParam')
  }

  getDroneFileUploadedValue () {
    return this.getReactiveValue('__dockDroneFileUploadedParam')
  }

  getDroneLiveCapacityValue () {
    return this.getReactiveValue('__droneLiveCapacityParam')
  }

  getDroneControlSourceValue () {
    return this.getReactiveValue('__dockControlSourceParam')
  }

  getDockMediaCountInfoUpdatedValue () {
    return this.getReactiveValue('__dockMediaCountInfoUpdatedParam')
  }

  getGatewayPushStartOrEndValue () {
    return this.getReactiveValue('__gatewayPushStartOrEndParam')
  }

  getAircraftPushStartOrEndValue () {
    return this.getReactiveValue('__aircraftPushStartOrEndParam')
  }

  getAircraftThermalSupportStylesValue () {
    return this.getReactiveValue('__aircraftThermalSupportStylesParam')
  }

  getDroneLiveStatusValue () {
    return this.getReactiveValue('__droneLiveStatusParam')
  }

  getOnlineValue () {
    return this.getReactiveValue('__onlineParam')
  }

  getDockPsdkWidgetValue () {
    return this.getReactiveValue('__dockPsdkWidgetValuesParam')
  }

  getDockPsdkSpeakerPlayStartProgressValue () {
    return this.getReactiveValue('__dockPsdkSpeakerPlayStartProgressParam')
  }

  getDockPsdkUiResourceUploadValue () {
    return this.getReactiveValue('__dockPsdkUiResourceUploadReceiveParam')
  }

  getAircraftLiveStream () {
    if (this.droneType === DroneType.TEMP) {
      console.warn('临时飞机不具有飞行器播放能力')
    }
    if (!this.aircraftLiveStream) {
      this.aircraftLiveStream = new LiveStreamAircraft(this.drone)
      this.aircraftLiveStream.setLiveCapacity(this.getDroneLiveCapacityValue())
      this.aircraftLiveStream.setLiveStatus(this.getDroneLiveStatusValue())
      this.aircraftLiveStream.setPushStartOrEnd(this.getAircraftPushStartOrEndValue())
      this.aircraftLiveStream.setThermalSupportStyles(this.getAircraftThermalSupportStylesValue())
      this.aircraftLiveStream.setOnline(this.getOnlineValue())
    }
    return this.aircraftLiveStream
  }

  getGatewayLiveStream () {
    if (this.droneType === DroneType.RC) {
      console.warn('遥控飞机不具有遥控播放能力')
    }
    if (!this.gatewayLiveStream) {
      this.gatewayLiveStream = new LivestreamGateway(this.drone)
      this.gatewayLiveStream.setOnline(this.getOnlineValue())
      this.gatewayLiveStream.setLiveCapacity(this.getDroneLiveCapacityValue())
      this.gatewayLiveStream.setLiveStatus(this.getDroneLiveStatusValue())
      this.gatewayLiveStream.setPushStartOrEnd(this.getGatewayPushStartOrEndValue())
    }
    return this.gatewayLiveStream
  }

  getDockSpeaker () {
    if (this.droneType !== DroneType.DOCK) {
      console.warn('非机场设备不具有喊话器播放能力')
    }
    if (!this.dockSpeaker) {
      this.dockSpeaker = new PsdkSpeaker(this.drone)
      this.dockSpeaker.setOnline(this.getOnlineValue())
      this.dockSpeaker?.setPsdkUiResourceUploadReceive(this.getDockPsdkUiResourceUploadValue())
      this.dockSpeaker.setPsdkWidgetValues(this.getDockPsdkWidgetValue())
      this.dockSpeaker.setPsdkSpeakerPlayStartProgress(this.getDockPsdkSpeakerPlayStartProgressValue())
    }
    return this.dockSpeaker
  }

  getValueTranslate () {
    if (!this.dockValues) {
      this.dockValues = new OsdGatewayValues(this)
    }
    return this.dockValues
  }

  getGatewayOnline () {
    return this.getOnlineValue()?.gatewayOnline
  }

  getAircraftOnline () {
    return this.getOnlineValue()?.aircraftOnline
  }
}
