import { FlightControlModule } from '@/store/FlightControl'
import BaseHelper from '@/common/helper/BaseHelper'
import { DroneJoystick } from '@/common/mqtt/jlink/DrcServiceMqtt'
import JlinkMqtt2 from '@/common/global/JlinkMqtt2'

export enum KeyCode {
  // 前 W
  KEY_W = 'KeyW',
  // 左转 A
  KEY_A = 'KeyA',
  // 后 S
  KEY_S = 'KeyS',
  // 右转 D
  KEY_D = 'KeyD',
  // 左 ←
  ARROW_LEFT = 'ArrowLeft',
  // 右 →
  ARROW_RIGHT = 'ArrowRight',
  // 上 ↑
  ARROW_UP = 'ArrowUp',
  // 下 ↓
  ARROW_DOWN = 'ArrowDown',
  // 急停 空格
  KEY_SPACE = 'Space',
  // 云台向上 I
  GIMBAL_UP = 'KeyI',
  // 云台向下 K
  GIMBAL_DOWN = 'KeyK',
  // 云台向左 J
  GIMBAL_LEFT = 'KeyJ',
  // 云台向右 L
  GIMBAL_RIGHT = 'KeyL',
  // 云台回中 N
  GIMBAL_CENTER = 'KeyN',
  // 拍照 P
  TAKE_PHOTO = 'KeyP',
  // 录像 O
  RECORD = 'KeyO',
  // 切换地图或者直播页面 M
  SWITCH_PANEL = 'KeyM',
  // 切换直播通道 Z
  SWITCH_PLAY_LEN = 'KeyZ',
  // 切换显示飞机信息模板 X
  TOGGLE_DRONE_INFO = 'KeyX',
  // 切换暂停航线或者恢复航线 C
  TOGGLE_WAYLINE_TASK = 'KeyC',
  // // 虚拟摇杆水平速度++ 1
  // HS_INCREASE = 'Digit1',
  // // 虚拟摇杆水平速度-- 2
  // HS_DECREASE = 'Digit2',
  // // 虚拟摇杆上升速度++ 3
  // VS_INCREASE = 'Digit3',
  // // 虚拟摇杆下降速度-- 4
  // VS_DECREASE = 'Digit4',
  // // 虚拟摇杆角速度++ 5
  // W_INCREASE = 'Digit5',
  // // 虚拟摇杆角速度-- 6
  // W_DECREASE = 'Digit6',
  // 变焦++ [
  ZOOM_INCREASE = 'BracketLeft',
  // 变焦-- ]
  ZOOM_DECREASE = 'BracketRight',
  // 一键返航 B
  RETURN_HOME = 'KeyB',

}

export default class DrcServiceBase extends BaseHelper {
  activeCallback: ((a: boolean) => void) | undefined = undefined
  handleEmergencyStop () {
    const dock = JlinkMqtt2.getReactiveDevice(FlightControlModule.data.currentDrone)?.getValueTranslate()
    if (dock) {
      dock.dispatchEmergencyStop()
    } else {
      console.error('dock is not ready')
    }
  }

  dispatchDroneControl (data: DroneJoystick) {
    const dock = JlinkMqtt2.getReactiveDevice(FlightControlModule.data.currentDrone)?.getValueTranslate()
    if (dock) {
      dock.dispatchJoystick(data)
    } else {
      console.error('dock is not ready')
    }
  }

  cameraAim (params: number[]) {
    params[0] = params[0].range(1, 0)
    params[1] = params[1].range(1, 0)
    const dock = JlinkMqtt2.getReactiveDevice(FlightControlModule.data.currentDrone)?.getAircraftLiveStream()
    if (dock) {
      dock.cameraAimSet(params.join(','), true).then()
    } else {
      console.error('dock is not ready')
    }
  }

  returnBack () {
    const dock = JlinkMqtt2.getReactiveDevice(FlightControlModule.data.currentDrone)?.getValueTranslate()
    if (dock) {
      dock.triggerReturnHome(true).then()
    } else {
      console.error('dock is not ready')
    }
  }

  takePhoto () {
    const dock = JlinkMqtt2.getReactiveDevice(FlightControlModule.data.currentDrone)?.getAircraftLiveStream()
    if (dock) {
      dock.cameraPhotoTake(true).then()
    } else {
      console.error('dock is not ready')
    }
  }

  toggleRecord () {
    const dock = JlinkMqtt2.getReactiveDevice(FlightControlModule.data.currentDrone)?.getAircraftLiveStream()
    if (dock) {
      dock.cameraRecordToggle(true).then()
    } else {
      console.error('dock is not ready')
    }
  }

  switchLiverLen () {
    const dock = JlinkMqtt2.getReactiveDevice(FlightControlModule.data.currentDrone)?.getAircraftLiveStream()

    if (dock) {
      dock.switchLenCircle(true).then()
    } else {
      console.error('dock is not ready')
    }
  }

  toggleWaylineTaskPauseOrResume () {
    const dock = JlinkMqtt2.getReactiveDevice(FlightControlModule.data.currentDrone)?.getValueTranslate()
    if (dock) {
      dock.pauseOrRecoveryFlightTask(true).then()
    } else {
      console.error('dock is not ready')
    }
  }

  gimbalReset (gimbalResetMode: 0 | 1 | 2 | 3) {
    const dock = JlinkMqtt2.getReactiveDevice(FlightControlModule.data.currentDrone)?.getAircraftLiveStream()
    if (dock) {
      dock.cameraGimbalDirectionReset(gimbalResetMode, true).then()
    } else {
      console.error('dock is not ready')
    }
  }

  zoomIncrease (factor: number = 1) {
    const dock = JlinkMqtt2.getReactiveDevice(FlightControlModule.data.currentDrone)?.getAircraftLiveStream()
    if (dock) {
      dock.cameraFocalLengthAsyncSet('zoomIncrease', factor, true).then()
    } else {
      console.error('dock is not ready')
    }
  }

  zoomDecrease (factor: number = 1) {
    const dock = JlinkMqtt2.getReactiveDevice(FlightControlModule.data.currentDrone)?.getAircraftLiveStream()
    if (dock) {
      dock.cameraFocalLengthAsyncSet('zoomDecrease', factor, true).then()
    } else {
      console.error('dock is not ready')
    }
  }

  cameraFocalLengthReset () {
    const dock = JlinkMqtt2.getReactiveDevice(FlightControlModule.data.currentDrone)?.getAircraftLiveStream()
    if (dock) {
      dock.cameraFocalLengthReset().then()
    } else {
      console.error('dock is not ready')
    }
  }

  destroy () {
    super.destroy()
    this.activeCallback = undefined
  }

  async create (activeCallback: (a: boolean) => void) {
    this.activeCallback = activeCallback
  }
}
