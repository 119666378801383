// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import WorkerScript from 'worker-loader!./script/WorkerDownload.ts'
import JlinkWorkerBase from '@/common/workers/JlinkWorkerBase'
import { AxiosProgressEvent } from 'axios'
import JSZip from 'jszip'
import JlinkUtils from '@/common/global/JlinkUtils'
import FileSaver from 'file-saver'
import { reactive } from 'vue'
import JlinkDownload from '@/common/global/JlinkDownload'

export default class JlinkWorkerDownLoad extends JlinkWorkerBase<AxiosDownload> {
  uuid = JlinkUtils.random.uuid(8)
  private zip = new JSZip()
  private notes: string[] = []
  reactiveValue = reactive<
    {
      currentState?: 'compressing' | 'download' | 'fail' | 'progress' | 'get' | 'complete' | 'signed'|'ziping'
      currentFileIndex?: number
      currentFileName?: string
      currentUploadProgress?: AxiosProgressEvent
      totalNum?: number,
      downloaded?: number
      createTime?: string
    }
  >({})

  constructor (files: { id: string, name: string, url: string, oss: boolean }[], zipName?: string) {
    super(WorkerScript())
    const _this = this
    this.setCallback(function (response) {
      console.log('JlinkWorkerDownloadResponse', response.data)
      switch (response.data.type) {
        case 'sign':
          const file = response.data.file
          window.$oss.getSignUrl(file.url)
            .then((res) => {
              file.url = res
              _this.post({ type: 'sign', file })
            })
          _this.reactiveValue.currentState = 'signed'
          break
        case 'complete':
          _this.reactiveValue.totalNum = response.data.totalNum
          _this.reactiveValue.downloaded = response.data.downloaded
          _this.reactiveValue.createTime = JlinkUtils.date.parseTime(Date.now())
          _this.zip.file(Date.now() + 'log.txt', _this.notes.join('\n'))
          if (zipName) {
            _this.reactiveValue.currentState = 'compressing'
            _this.zip.generateAsync({
              type: 'blob',
              compression: 'DEFLATE',
              compressionOptions: { level: 9 }
            }).then((blob) => {
              const zname = zipName.endsWith('.zip') ? zipName : zipName + '.zip'
              FileSaver.saveAs(blob, zname)
              _this.reactiveValue.currentFileName = zname
              _this.reactiveValue.currentState = 'complete'
              setTimeout(function () {
                JlinkDownload.removeDownLoad(_this.uuid)
              }, 2000)
            })
          } else {
            _this.reactiveValue.currentState = 'complete'
            setTimeout(function () {
              JlinkDownload.removeDownLoad(_this.uuid)
            }, 2000)
          }

          break
        case 'download':
          const fileBlob = response.data.fileBlob
          if (fileBlob) {
            try {
              const fn = fileBlob.name
              const blob = fileBlob.blob
              if (zipName) {
                _this.zip.file(fn, blob, { binary: true })
                _this.reactiveValue.currentState = 'ziping'
              } else {
                const linkNode = document.createElement('a')
                // constant evt = document.createEvent('HTMLEvents')
                // evt.initEvent('click')
                linkNode.style.display = 'none'
                linkNode.download = fn
                linkNode.href = URL.createObjectURL(blob) // 生成一个Blob URL
                document.body.appendChild(linkNode)
                // linkNode.dispatchEvent(evt)
                linkNode.click() // 模拟在按钮上的一次鼠标单击
                URL.revokeObjectURL(linkNode.href) // 释放URL 对象
                document.body.removeChild(linkNode)
                _this.reactiveValue.currentState = 'get'
              }
            } catch (e: any) {
              _this.notes.push(e.message)
            }
            if (_this.reactiveValue.currentUploadProgress) {
              if (_this.reactiveValue.currentUploadProgress.total) {
                _this.reactiveValue.currentUploadProgress.loaded = _this.reactiveValue.currentUploadProgress.total
              }
              _this.reactiveValue.currentUploadProgress.progress = 1
            }
            _this.reactiveValue.currentState = 'download'
          } else {
            _this.notes.push(response.data.message)
            _this.reactiveValue.currentState = 'fail'
          }
          break
        case 'progress':
          _this.reactiveValue.currentFileIndex = response.data.currentFileIndex
          _this.reactiveValue.currentFileName = response.data.currentFileName
          _this.reactiveValue.totalNum = response.data.totalNum
          _this.reactiveValue.downloaded = response.data.downloaded
          _this.reactiveValue.currentUploadProgress = JSON.parse(response.data.currentUploadProgress) as AxiosProgressEvent
          _this.reactiveValue.currentState = 'progress'
          break
      }
    })
    this.post({ type: 'download', files })
  }

  destroy () {
    super.destroy()
  }
}
